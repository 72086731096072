import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  template: `
  <div *ngIf="data">
    <mat-dialog-content>{{data}}</mat-dialog-content>
  </div>
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="close()">Aceptar</button>
  </div>`
})

export class ModalDialogInfo {

  constructor(
    public dialogRef: MatDialogRef<ModalDialogInfo>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  close(): void {
    this.dialogRef.close()
  }
}