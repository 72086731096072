import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import {Subject} from 'rxjs';
import {CategoryService} from '../../../services/category.service';

import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

import {ActivatedRoute, Router} from '@angular/router';
import {ModelService} from '../../../services/model.service';
import {ClientService} from '../../../services/client.service';
import {CapService} from '../../../services/cap.service';
import {BroadcastService} from '../../../services/bradcast.services';

@Component({
    selector: 'app-edit-cap',
    templateUrl: './edit.cap.component.html',
    styleUrls: ['./edit.cap.component.scss']
})
export class EditCapComponent implements OnInit {
    cap = {};
    capForm;

    errorMessage: string;
    categories: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    clientId;
    id;
    capData;

    constructor(
        private _formBuilder: FormBuilder,
        private categoryService: CategoryService,
        private modelService: ModelService,
        private fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private _clientService: ClientService,
        private _capService: CapService,
        private _broadCastService: BroadcastService
    ) {
        this.clientId = this._clientService.getCurrentClient().clientId;
    }

    ngOnInit(): void {
        this.cap = {};
        this.id = this.route.snapshot.paramMap.get('id');
        this._capService.getCap(this.id).subscribe(capData =>{
            this.capData = capData;
            this.capForm = this._formBuilder.group({
                sku: [capData.sku, Validators.required],
                state: [capData.sku, Validators.required],
            });
        })
    }

    onSubmit(): void {
        console.log(this.capForm.getRawValue());
        this.fuseProgressBarService.show();
        if (this.capForm.invalid) {
            console.log('IS INVALID');
            return;
        }
        let capData = this.capForm.getRawValue();
        capData.id = this.id;
        this._capService.updateCap(capData).subscribe((data) => {
            this.fuseProgressBarService.hide();
            this.showSnackBar('SKU  actualizado');
            this.router.navigateByUrl('cap/list');
        });
    }

    showSnackBar(message: string): void {
        this._snackBar.open(message, 'Ok', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
    }

}
