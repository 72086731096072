import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { IHotSpotVersion, IHotSpotAdditionalAttr } from '../../../hotspots.model';
@Component({
  selector: 'app-section-general',
  templateUrl: './section-general.component.html',
  styleUrls: ['./section-general.component.scss']
})
export class SectionGeneralComponent implements OnInit {
  
  @Input() currentVersion: IHotSpotVersion;
  @Input() sku: string;
  @Input() availableBulkLoad:any;
  @Output() handleCreateHotspotsFromIndexado = new EventEmitter<boolean>();

  @Output() onChangeHotspotConfig = new EventEmitter<IHotSpotAdditionalAttr>();

  private isOpen = true

  constructor(
    private modalDialogService: ModalService
  ) { }

  ngOnInit(): void {
  }

  onChange(event: any, field: string): void {
    console.log(event, field);
    this.onChangeHotspotConfig.emit({
        key: field,
        value: event.target ? event.target.value : event.value
    });
  }
  
  openAccordeonElement(open: boolean): void {
    this.isOpen = open
  }

  cbFunc = async (data:any) => {
    console.log('Data 23:', data)
  }

  bulkLoad = () => {
    this.modalDialogService.selectModalType('BULKLOAD_HOTSPOTS_MODAL', {
      title: 'Carga masiva de hotspots',
      version: this.currentVersion.version,
      baseSku: this.sku,
      language: this.currentVersion.language
    },  this.cbFunc)
  }

  // bulkLoadCreateHotspotFromIndexado = () => {
  //   this.modalDialogService.selectModalType('BULKLOAD_INDEXADO_HOTSPOTS_MODAL', {
  //     title: 'Carga masiva de hotspots, Sku con Indexado',
  //     version: this.currentVersion.version,
  //     baseSku: this.sku,
  //     language: this.currentVersion.language
  //   },  this.cbFunc)
  // }
  createHotspotsFromIndexado(create: boolean): void {
    this.handleCreateHotspotsFromIndexado.emit(create);
  }

  validateAvailable= (current)=>{
    const valor= this.availableBulkLoad.find(elem=> elem.id===current.version)
    if(valor){
      return valor.available
    }else{
      return false
    }
  }

}
