import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';

import {ListCapComponent} from './list/list.cap.component';

import {MatPaginatorModule} from '@angular/material/paginator';

// import { RouterModule, Routes } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRippleModule} from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckbox, MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CreateCapComponent } from './create/create.cap.component';
import { EditCapComponent } from './edit/edit.cap.component';
import {InputMultimediaComponent} from '../../multimedia/input.multimedia/input.multimedia.component';
import {IndexadoModule} from '../indexado/indexado.module';


const routes = [
    {
        path: 'cap/list',
        component: ListCapComponent
    },
    {
        path: 'cap/create',
        component: CreateCapComponent
    },
    {
        path: 'cap/edit/:id',
        component: EditCapComponent
    }
];

@NgModule({
    declarations: [
        ListCapComponent,
        CreateCapComponent,
        EditCapComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatTableModule,
        MatDialogModule,
        MatToolbarModule,
        MatRippleModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        IndexadoModule

    ],
    entryComponents: [
        InputMultimediaComponent
    ]
})
export class CapModule {
}
