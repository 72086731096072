import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHotSpot, IHotSpotAdditionalAttr } from '../../../hotspots.model';

@Component({
  selector: 'app-section-video',
  templateUrl: './section-video.component.html',
  styleUrls: ['./section-video.component.scss']
})
export class SectionVideoComponent implements OnInit {

  @Input() currentHotspot: IHotSpot
  @Output() changeAttr = new EventEmitter<IHotSpotAdditionalAttr>()

  private isOpen = true

  urlValue: string

  constructor() { }

  ngOnInit(): void {
    const obj = this.currentHotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'URL');
    this.urlValue = obj ? obj.value : '';
  }

  onChangeAttr(event: any, field: string): void {
    this.changeAttr.emit({
      key: field,
      value: event.target.value
    });
  }

  openAccordeonElement(open: boolean): void {
    this.isOpen = open
  }
  
}
