import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-text',
  templateUrl: './content-text.component.html',
  styleUrls: ['./content-text.component.scss']
})

export class ContentTextComponent implements OnInit {

  @Input() text: string;
  @Input() title: string;
  @Input() contentPosition: object;

  constructor() { }

  ngOnInit(): void {
  }

}
