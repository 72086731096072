import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-app-add-new-element',
  templateUrl: './modal-add-new-element.component.html',
  styleUrls: ['./modal-add-new-element.component.scss']
})
export class ModalAddNewElementComponent implements OnInit {

  public message: string;
  constructor(
    public dialogRef: MatDialogRef<ModalAddNewElementComponent>,
  ) {
    this.message = "";
   }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  accept() {
    this.dialogRef.close({message: this.message});
  }

}
