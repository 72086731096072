import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';

import {ListModelComponent} from './list/list.model.component';

import {MatPaginatorModule} from '@angular/material/paginator';

// import { RouterModule, Routes } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRippleModule} from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckbox, MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CreateModelComponent } from './create/create.model.component';
import { EditModelComponent } from './edit/edit.model.component';


const routes = [
    {
        path: 'model/list',
        component: ListModelComponent
    },
    {
        path: 'model/create',
        component: CreateModelComponent
    },
    {
        path: 'model/edit',
        component: CreateModelComponent
    },
    {
        path: 'model/edit/:id',
        component: EditModelComponent
    }
];

@NgModule({
    declarations: [
        ListModelComponent,
        CreateModelComponent,
        EditModelComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatTableModule,
        MatDialogModule,
        MatToolbarModule,
        MatRippleModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSlideToggleModule

    ],
    entryComponents: [
    ]
})
export class ModelModule {
}
