import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Category} from '../main/category/category.model';
import {Observable} from 'rxjs';

import {environment as env} from '../../environments/environment';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';


@Injectable({
    providedIn: 'root'
})
/**
 * Servicio que obtiene y gestiona las categorías de producto
 */
export class CategoryService {

    /**
     * Constructor
     *
     * @param {HttpClient} httpClient
     */
    constructor(
        private httpClient: HttpClient
    ) {
    }

    getCategory(id): Observable<Category> {
        return this.httpClient.get<Category>(`${env.apiUrl}/categories/${id}`);
    }

    getCategories(clientId = 'HP'): Observable<Category[]> {
        let filter = {
            order: 'created DESC',
            where: {
                state: {
                    neq: false
                },
                clientId : clientId,
            }
        };
        let params = new HttpParams();
        console.log('clientId : ', clientId);
        params = params.append('filter', JSON.stringify(filter));
        return this.httpClient.get<Category[]>(`${env.apiUrl}/categories`,{params: params});
    }

    addCategory(category: Category): Observable<Category> {
        return this.httpClient.post<Category>(`${env.apiUrl}/categories`, category);
    }

    updateCategory(category): Observable<any> {
        return this.httpClient.patch(`${env.apiUrl}/categories/${category.id}`, category);
    }

    deleteCategory(category: Category): Observable<any> {
        return this.httpClient.delete(`${env.apiUrl}/categories/${category.id}`);
    }

    toFormGroup(category: Category ) {
        const group: any = {};
        const categoryForm: any = {};

        categoryForm.name =  new FormControl(category.name || '', Validators.required);
        categoryForm.description =  new FormControl(category.description || '', Validators.required);
        categoryForm.specs = new FormArray([])

        category.specs.forEach(spec => {
            group.key =  new FormControl(spec.key || '', Validators.required);
            group.label =  new FormControl(spec.label || '', Validators.required);
        });

        return new FormGroup(group);
    }

}
