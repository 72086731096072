import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalDialogDataText } from '../../../hotspots.model';
import { MultimediaDialogComponent } from '../../../../../../multimedia/dialog/multimedia-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ModelService } from 'app/services/model.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-modal-add-ksp',
  templateUrl: './modal-add-ksp.component.html',
  styleUrls: ['./modal-add-ksp.component.scss']
})
export class ModalAddKspComponent implements OnInit {
  @Input() sku: string;
  title: string;
  text: string;
  icono: string;
  image: string;
  imageReference: {key: string, value: string};

  constructor(
    public dialogRef: MatDialogRef<ModalAddKspComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalDialogDataText,
    private modelService: ModelService,
    public multimedia: MatDialog,
  ) {
    this.sku = this.data.sku;
    this.image = "";
    this.imageReference = {key: 'imageReference', value: ''};
  }

  ngOnInit(): void {}

  onClick(): void {
    this.dialogRef.close({
      action: 'save', 
      data: {
        title: this.title, 
        text: this.text,
        icono: this.icono,
        image: this.image,
       }
    });
  }

  onNoClick(): void {
    this.dialogRef.close({status: 'cancel'});
  }

  onAdd(): void {
    const newElem = {
      title: this.title,
      text: this.text,
      icono: this.icono,
    }
    this.data.texts = [...this.data.texts, newElem];
    this.title = '';
    this.text = '';
    this.icono = '';
  }

  onDelete(elem: any) {
    this.data.texts = this.data.texts.filter((e: any) => e.title !== elem.title);
  }

  addImage() {
    const dialogRef = this.multimedia.open(MultimediaDialogComponent, {
      data: {
        element: this.imageReference,
        multi: false,
        fileUploadUrl: environment.apiFilesUrl,
        type: "images"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.storageId) {
          this.image = 'https://media.alquimio.cloud/images/@thumbs_256_' + result.storageId + '.webp';
        }
    });
  }
} 
 