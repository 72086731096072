import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CategoryService} from '../../../services/category.service';
import {Category} from '../category.model';
import {MatDialog} from '@angular/material/dialog';
import {fuseAnimations} from '@fuse/animations';
import {FormGroup} from '@angular/forms';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {ClientService} from '../../../services/client.service';
import {BroadcastService} from '../../../services/bradcast.services';
import { ModalService } from 'app/services/modal.service';
import { ERROR_DELETE_USER, ERROR_GET_CATEGORIES_LIST, INFO_DELETED_USER } from 'app/Messages';


@Component({
    selector: 'list.category',
    templateUrl: './list.category.component.html',
    styleUrls: ['./list.category.component.scss'],
    animations: fuseAnimations
})
export class ListCategoryComponent implements OnInit {

    displayedColumns: string[] = ['name', 'buttons'];
    dataSource: MatTableDataSource<Category>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    public title: string;
    loading: boolean;
    public categories: Category[];
    clientId;
    /**
     * Constructor
     */
    constructor(
        private categoryService: CategoryService,
        public _matDialog: MatDialog,
        public router: Router,
        private fuseProgressBarService: FuseProgressBarService,
        private _clientService: ClientService,
        private broadcastService: BroadcastService,
        private modalDialogService: ModalService
    ) {
        this.loading = true;
        this.fuseProgressBarService.show();
        this.clientId = this._clientService.getCurrentClient().clientId;
        this.getCategories();
    }

    getCategories(){
        this.categoryService.getCategories(this.clientId).subscribe((data) => {
            this.categories = data;
            this.dataSource = new MatTableDataSource(this.categories);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loading = false;
        }, (err) => {
            this.modalDialogService.selectModalType('ERRORMODAL', {
                message: ERROR_GET_CATEGORIES_LIST,
                error: err
            })
        });
    }

    ngOnInit(): void {
        this.title = 'Lista de Categorías';
        this.broadcastService.subscribe((val) => {
                if (val.type === 'client' && val.value.clientId) {
                    this.clientId = val.value.clientId;
                    this.getCategories();
                }
            }
        );
    }

    updateListCategories(): void {
        this.categoryService.getCategories().subscribe((data) => {
            this.categories = data;
            this.dataSource = new MatTableDataSource(this.categories);

        }, (err) => {
            this.modalDialogService.selectModalType('ERRORMODAL', {
                message: ERROR_GET_CATEGORIES_LIST,
                error: err
            })
        });
    }

    applyFilter(filterValue: string): any {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    deleteCategory(category: Category): void {

        this.categoryService.deleteCategory(category).subscribe((data) => {
            this.modalDialogService.selectModalType('INFOMODAL', INFO_DELETED_USER)
            this.updateListCategories();
        }, (err) => {
            console.error('error', err);
            this.modalDialogService.selectModalType('ERRORMODAL', {
                message: ERROR_DELETE_USER,
                error: err
            })
        });

    }


    /**
     * Edit category
     *
     * @param category
     */
    editCategory(category): void {
        this.router.navigateByUrl('/category/edit/' + category.id);
    }


}
