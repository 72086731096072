import { Pipe, PipeTransform } from '@angular/core';

interface IItem {
    name: string
    version: string
    language: string
}

@Pipe({
  name: 'customVersionNameFilter',
  pure: false
})
export class CustomVersionNameFilterPipe implements PipeTransform {
  transform(items: IItem[] = [], version: string = '', language: string = ''): IItem[] {
    if (!items) return [];
    if (!version) return items;
    return items.filter((item: IItem) => item.version === version && item.language === language);
  }
}
