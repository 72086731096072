import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import {Subject} from 'rxjs';
import {CategoryService} from '../../../services/category.service';

import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

import {ActivatedRoute, Router} from '@angular/router';
import {ClientService} from '../../../services/client.service';

@Component({
    selector: 'create.category',
    templateUrl: './create.category.component.html',
    styleUrls: ['./create.category.component.scss']
})
export class CreateCategoryComponent implements OnInit, OnDestroy {

    public title: string;
    category: any;
    categoryForm: FormGroup;
    errorMessage: string;
    clientId: string;
    // Private
    private _unsubscribeAll: Subject<any>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    /**
     * Constructor
     *
     * @param {FormBuilder} _categoryFormBuilder
     * @param categoryService
     * @param fuseProgressBarService
     * @param _snackBar
     * @param router
     */
    constructor(
        private _formBuilder: FormBuilder,
        private categoryService: CategoryService,
        private fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private _clientService: ClientService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // Reactive Form


    ngOnInit(): void {
        this.title = 'Crear Categoría';
        this.clientId = this._clientService.getCurrentClient().clientId;

        this.fuseProgressBarService.setMode('indeterminate');
        this.categoryForm = this._formBuilder.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            displayOnExport: [true, Validators.required],
            specs: new FormArray([])
        });

    }

    get f() {
        return this.categoryForm.controls;
    }

    get t() {
        return this.f.specs as FormArray;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onSubmit(): void {
        console.log(this.categoryForm.getRawValue());
        this.fuseProgressBarService.show();

        // stop here if form is invalid
        if (this.categoryForm.invalid) {
            console.log('IS INVALID');
            return;
        }
        let valueForm = this.categoryForm.value;
        valueForm.state = true;
        valueForm.clientId = this.clientId;
        debugger;
        this.categoryService.addCategory(this.categoryForm.value).subscribe((data) => {
            this.fuseProgressBarService.hide();
            this.showSnackBar('Categoria Creada');
            this.router.navigateByUrl('category/list');
        });
    }

    initSpecRow(): FormGroup {
        return this._formBuilder.group({
            label: [null, [Validators.required]],
            key: [null, [Validators.required]],
            displayOnExport: [false, [Validators.required]]
        });
    }

    removeSpecRow(rowIndex: number): void {
        const specs = <FormArray>this.categoryForm.controls['specs'];
        if (specs.length > 1) {
            specs.removeAt(rowIndex);
        } else {
            this.errorMessage = 'You cannot delete this row! form should contain at least one row!';
            setTimeout(() => {
                this.errorMessage = null;
            }, 4000);
        }
    }


    addSpec(): void {
        const specs = <FormArray>this.categoryForm.controls['specs'];
        specs.push(this.initSpecRow());
    }

    deleteSpec(index): void {
        this.removeSpecRow(index);

    }

    showSnackBar(message: string): void {
        this._snackBar.open(message, 'Ok', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
    }


}
