import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../user.model';
import {ProfileType, UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import * as _ from 'lodash';
import { ModalService } from 'app/services/modal.service';
import { ERROR_LOAD_PROFILES } from 'app/Messages';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UserFormComponent implements OnInit {

  action: string;
  user: User;
  profiles: ProfileType[];
  formEnabled: boolean;
  userForm: FormGroup;
  dialogTitle: string;
  allProfiles: ProfileType[];
  allProfilesNC: ProfileType[];
  allProfilesCLI: ProfileType[];
  appsSettingsDefault: any;

  /**
   * Constructor
   *
   * @param {MatDialogRef<UserFormComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public matDialogRef: MatDialogRef<UserFormComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private _authService: AuthService,
    private modalDialogService: ModalService
  ) {
    // Set the defaults
    this.action = _data.action;
    this.appsSettingsDefault = [{
      name: 'NC',
      settings: {
        maxAmmount: 0
      }
    }];
    if (this.action === 'edit') {
      this.dialogTitle = 'Editar Usuario';
      if (!_data.user.appsSettings) {
        _data.user.appsSettings = {nc: {maxAmmount: 0}};
      }

      this.user = _data.user;
      if (!this.user.profiles) {
        this.user.profiles = {};
        this.user.profiles.CLI = [];
        this.user.profiles.NC = [];
      }


      this.formEnabled = true;

      console.log('user', this.user);

    } else if (this.action === 'show') {
      this.dialogTitle = 'Usuario';
      this.user = _data.user;
      if (!this.user.profiles) {
        this.user.profiles = {};
        this.user.profiles.CLI = [];
        this.user.profiles.NC = [];
      }
      this.formEnabled = false;

    } else {
      this.dialogTitle = 'Nuevo Usuario';
      this.user = new User({});
      this.formEnabled = true;
    }

    this.userForm = this.createUserForm();
  }

  ngOnInit(): void {
      this.userService.getProfiles().subscribe((profiles) => {
          this.profiles = profiles;
      }, (err) => {
        this.modalDialogService.selectModalType('ERRORMODAL', {
            message: ERROR_LOAD_PROFILES,
            error: err
        })
      });


  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create contact form
   *
   * @returns {FormGroup}
   */
  createUserForm(): FormGroup {
    return this._formBuilder.group({
      username: [{value: this.user.username, disabled: !this.formEnabled}],
      name: [{value: this.user.name, disabled: !this.formEnabled}],
      lastname: [{value: this.user.lastname, disabled: !this.formEnabled}],
      email: [{value: this.user.email, disabled: !this.formEnabled}],
      rut: [{value: this.user.rut, disabled: !this.formEnabled}],
      profilesNC: [{value: this.user.profiles.NC, disabled: !this.formEnabled}],
      profilesCLI: [{value: this.user.profiles.CLI, disabled: !this.formEnabled}],
      maxAmmount: [{value: this.user.maxAmmount, disabled: !this.formEnabled}]
    });
  }

}
