import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import {Subject} from 'rxjs';
import {CategoryService} from '../../../services/category.service';

import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

import {ActivatedRoute, Router} from '@angular/router';
import {ModelService} from '../../../services/model.service';
import {ClientService} from '../../../services/client.service';
import {BroadcastService} from '../../../services/bradcast.services';
import {Category} from '../../category/category.model';

@Component({
    selector: 'app-edit.model',
    templateUrl: './edit.model.component.html',
    styleUrls: ['./edit.model.component.scss']
})
export class EditModelComponent implements OnInit {
    model = {};
    modelForm:FormGroup;

    errorMessage: string;
    categories: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    clientId;
    id;
    modelData;
    constructor(private _formBuilder: FormBuilder,
                private categoryService: CategoryService,
                private modelService: ModelService,
                private fuseProgressBarService: FuseProgressBarService,
                private _snackBar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute,
                private _clientService: ClientService,
                private _broadCastService: BroadcastService) {
        this.clientId = this._clientService.getCurrentClient().clientId;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.modelService.getModel(this.id).subscribe(modelData =>{
            this.modelData = modelData;

            // Por ahora está implementado para el lenguaje esCL
            // Falta crear una implementación para múltiples lenguajes

            this.modelForm = this._formBuilder.group({
                name: [modelData.name, Validators.required],
                sku: [modelData.sku, Validators.required],
                skuRetail: [modelData.langs['esCL'].skuRetail],
                categoryId: [modelData.categoryId, Validators.required],
                eanString: [modelData.langs['esCL'].eanString],
                description: [modelData.description],
                langs: [modelData.langs]
            });
        })

        this.categoryService.getCategories(this.clientId).subscribe((res) => {
            this.categories = res;
        })
        this._broadCastService.subscribe((val) => {
                if (val.type === 'client' && val.value.clientId) {
                    this.clientId = val.value.clientId;
                    this.categoryService.getCategories(this.clientId).subscribe((res) => {
                        this.categories = res;
                    })
                    console.log('Cambio ClientId : ', this.clientId);
                }
            }
        );
    }
    onSubmit():void {
        this.modelData = this.modelForm.getRawValue();
        this.modelData.id = this.id;
        for (const lang in this.modelData.langs) {
            this.modelData.langs[lang].eanString = this.modelData.eanString;
            this.modelData.langs[lang].skuRetail = this.modelData.skuRetail;
        }
        this.fuseProgressBarService.show();

        this.modelService.updateModel(this.modelData).subscribe((data )=>{
            this.fuseProgressBarService.hide();
            this.showSnackBar('Modelo Actualizado');
            this.router.navigateByUrl('model/list');
        })
    }
    get f() {
        return this.modelForm.controls;
    }
    showSnackBar(message: string): void {
        this._snackBar.open(message, 'Ok', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
    }

}
