import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { ICustomVersion } from 'app/main/project/project.model';

@Injectable({
  providedIn: 'root'
})
export class IndexadoService {

  constructor(
    private http: HttpClient
  ) { }


  getCustomsVersionNames(version: string): Observable<{data: {list: ICustomVersion[], versionSelected: string}}> {
    return this.http.get<{data: {list: ICustomVersion[], versionSelected: string}}>(`${environment.indexadoApiUrl}/indexadoDocs/getVersions`, {
      params: {
        version
      }
    })
    .pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  save(modelId: string, arr: any[], versionSelected: {language: string, version: string}[], sku: string, indexadoState: boolean): Observable<any> {
    const data = {
        list: arr,
        versionSelected,
        sku,
        state: indexadoState

    }
    return this.http.post(`${environment.indexadoApiUrl}/indexadoDocs/save`, {
        modelId,
        data,
    })
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getModel(modelId: string): Observable<any> {
    return this.http.get(`${environment.indexadoApiUrl}/indexadoDocs/getAll/${modelId}`)
    .pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  getSkusByVersion(version: string): Observable<{status: number, data: string[]}> {
    return this.http.get<{status: number, data: string[]}>(`${environment.indexadoApiUrl}/indexadoDocs/getSkusByVersion`, {
      params: {
        version
      }
    })
    .pipe(
      retry(3),
      catchError(this.handleError)
    )
  }


  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
