import { AfterViewChecked, AfterViewInit, Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHotSpot, IHotSpotAdditionalAttr } from '../../hotspots.model';

interface XYPairValues {
  x: number,
  y: number
}

@Component({
  selector: 'app-hotspot-modal-preview',
  templateUrl: './hotspot-modal-preview.component.html',
  styleUrls: ['./hotspot-modal-preview.component.scss']
})

export class HotspotModalPreviewComponent implements OnInit {

  @Output() onClose = new EventEmitter();
  @Input() hotspot: IHotSpot;

  public margin: string = ''

  constructor() { }

  ngOnInit(): void {
    //TODO: make refector to function getContentPosition down
    if (this.margin == '') this.margin = this.getContentPosition()
  }

  close(): void {
    this.onClose.emit()
  }

  getUrl(): string {
    const obj = this.hotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'URL')
    
    return obj ? obj.value : ''
  }

  getText(): string {
    const obj = this.hotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'TEXT')

    return obj ? obj.value : ''
  }

  getKsp(): string[] {
    const obj = this.hotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'KSP')

    return obj ? obj.value : []
  }

  getImages(): string[] {
    const obj = this.hotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'IMAGES')

    return obj ? obj.value : []
  }

  getContentPosition(): string {
    const backgroundImg = document.getElementsByClassName('hotspot__thumbnail')[0]
    // const modalDiv = document.getElementById('hotspotVideoContainer')
    const backgroundPixelDimensions: XYPairValues = this.getHTMLTagDims(backgroundImg)
    // const modalPixelDimensions: XYPairValues = this.getHTMLTagDims(modalDiv)
    const modalPixelDimensions: XYPairValues = {
      x: backgroundPixelDimensions.x / 2,
      y: backgroundPixelDimensions.x * 2 / 5
    }

    console.debug('PIXEL DIMS', backgroundPixelDimensions, modalPixelDimensions)

    const hotspotPosition: XYPairValues = {
      x: this.parseIntIfString(this.hotspot.layout.posX),
      y: this.parseIntIfString(this.hotspot.layout.posY)
    }
    const hotspotDimensions: XYPairValues = {
      x: this.parseIntIfString(this.hotspot.layout.width),
      y: this.parseIntIfString(this.hotspot.layout.height)
    }
    const hotspotRelativeCenter = {
      x: hotspotPosition.x + hotspotDimensions.x * 50 / backgroundPixelDimensions.x,
      y: hotspotPosition.y + hotspotDimensions.y * 50 / backgroundPixelDimensions.y
    }
    
    console.debug('HS DIMS', hotspotPosition, hotspotDimensions, hotspotRelativeCenter)

    const hotspotPlacementFactor: XYPairValues = this.getHSPlacementFactor(hotspotRelativeCenter)
    const positionAdjustment: XYPairValues = this.getPositionAdjustment(hotspotRelativeCenter, modalPixelDimensions, backgroundPixelDimensions)

    const contentPosition = {
      x: hotspotRelativeCenter.x + hotspotPlacementFactor.x * hotspotDimensions.x * 100 / backgroundPixelDimensions.x + positionAdjustment.x,
      y: hotspotRelativeCenter.y + hotspotPlacementFactor.y * hotspotDimensions.y * 100 / backgroundPixelDimensions.y + positionAdjustment.y
    }
    
    console.debug('POSITION', hotspotPlacementFactor, positionAdjustment, contentPosition)
    const adjustValue = 5
    const widthDevice = (window.innerWidth > 0) ? window.innerWidth : screen.width
    let positionDevices = 1
    if (widthDevice < 760) positionDevices = 4

    return `${contentPosition.y+adjustValue}% auto auto ${contentPosition.x-adjustValue*positionDevices}%`
  }

  getHSPlacementFactor(hotspotCenter: XYPairValues): XYPairValues {
    const maxContentdistanceFactor = .5
    const factor: XYPairValues = {
      x: maxContentdistanceFactor - hotspotCenter.x * maxContentdistanceFactor / 50,
      y: maxContentdistanceFactor - hotspotCenter.y * maxContentdistanceFactor / 50
    }

    return factor
  }

  getPositionAdjustment(hotspotCenter: XYPairValues, modalDims: XYPairValues, backgroundDims: XYPairValues): XYPairValues {
    const factor: XYPairValues = {
      x: - hotspotCenter.x * modalDims.x / backgroundDims.x,
      y: - hotspotCenter.y * modalDims.y / backgroundDims.y
    }

    return factor
  }

  parseIntIfString(value: any): number {
    const parsedValue: number = value ? typeof value == 'string' ? parseInt(value) : value : 1

    return parsedValue
  }

  getHTMLTagDims(element: any): XYPairValues {
    let dimensions: XYPairValues = {x: 1, y: 1}

    if (element instanceof HTMLElement) {
      dimensions.x = this.parseIntIfString(element.offsetWidth) + 1
      dimensions.y = this.parseIntIfString(element.offsetHeight) + 1
    }

    return dimensions
  }
}
