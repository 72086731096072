import {Injectable} from '@angular/core';

import {Subject, Subscription} from 'rxjs';


@Injectable()
export class BroadcastService {

  constructor() {
  }

  private handler: Subject<any> = new Subject<any>();

  broadcast(value: any) {
    this.handler.next(value);
  }

  subscribe(callback: (payload: any) => void): Subscription {
    return this.handler
      .subscribe(callback);
  }
}
