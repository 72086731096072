export class User {
  username: string;
  name: string;
  lastname: string;
  profiles: any;
  email: string;
  rut: string;
  password: string;
  appsSettings: any;
  maxAmmount: any;

  /**
   * Constructor
   *
   * @param user
   */
  constructor(user) {
    {
      this.username = user.username || '';
      this.name = user.name || '';
      this.lastname = user.lastname || '';
      this.profiles = user.profiles || '';
      this.email = user.email || '';
      this.password = user.password || '';
      this.rut = user.rut || '';
      this.appsSettings = user.settings || {};
      this.maxAmmount = user.maxAmmount || 0;
    }
  }
}
