import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { runInNewContext } from 'vm';
import {IHotSpot, IHotSpotVersion} from '../hotspots.model';

@Component({
  selector: 'app-hotspots-preview',
  templateUrl: './hotspots-preview.component.html',
  styleUrls: ['../hotspots.component.scss']
})
export class HotspotsPreviewComponent implements OnInit {

  isPresenting: boolean
  idHotspotsSelected:string = ''
  hotspot: IHotSpot

  @Input() currentVersion: IHotSpotVersion;
  @Input() sku: string;

  constructor() {
      this.currentVersion = null;
  }

  ngOnInit(): void {
    const NEWSCRIPT = document.createElement('script');
    NEWSCRIPT.id = 'tag-new-script';
    NEWSCRIPT.src = 'https://pervasivemind.com/hotspots/assets/alquimiohotspots.min.js';
    NEWSCRIPT.dataset.sku = this.sku;
    NEWSCRIPT.dataset.retail = this.currentVersion.retail;
    NEWSCRIPT.dataset.lang = this.currentVersion.language;
    NEWSCRIPT.dataset.version = this.currentVersion.id;
    document.head.appendChild(NEWSCRIPT);
  }

  open(hotspot: IHotSpot): void {
    this.onDestroy()
    this.hotspot = hotspot
    const idHotspot = this.currentVersion.hotspots.find(hotspot => hotspot.id === this.hotspot.id)
    if(idHotspot) this.idHotspotsSelected = idHotspot.id
    this.isPresenting = true
  }

  onDestroy(): void {
    this.hotspot = null;
    this.isPresenting = false;
    this.idHotspotsSelected = '';
    document.head.removeChild(document.getElementById('tag-new-script'));    
  }
}
