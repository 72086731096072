
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-footer-controls',
  templateUrl: './footer-controls.component.html',
  styleUrls: ['./footer-controls.component.scss']
})
export class FooterControlsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
