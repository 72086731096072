import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BroadcastService} from '../../../../../services/bradcast.services';
import {InputMultimediaComponent} from '../../../../../multimedia/input.multimedia/input.multimedia.component';
import {Color, Solver, hexToRgb} from './filterColor';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
    ngOnInit(): void {
        this.getFilter('#FFFFFF');
    }

    @Input() element: any;
    @Output() elementChangeHandler: EventEmitter<any> = new EventEmitter();

    sourceVideo: string;

    videoSources: any = ['vimeo', 'youtube'];
    insertOption: any = ['iframe', 'modal']
    playButtonColor: string;
    idVideoService: string;
    placeHolderImage: any;
    filterColor: string;
    constructor(
        private broadcastService: BroadcastService
    ) {

    }


    getFilter(hexValue=null){
        const rgb = hexToRgb(hexValue || this.element.playButtonColor);
        const  color = new Color(rgb[0], rgb[1], rgb[2]);
        const solver = new Solver(color);
        const result = solver.solve();
        this.element.playButtonFilter = result.filter;
    }



}
