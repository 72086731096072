import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../main/user/user.model';
import { environment as env } from '../../environments/environment';
import {ClientService} from './client.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  /**
   * Constructor
   *
   * @param {HttpClient} httpClient
   */
  constructor(
      private _clientService: ClientService
  ) {

  }

  isLogged(): boolean {
    if (localStorage.getItem('user')) {
      return true;
    } else {
      return false;
    }
  }

  getUser(): any {
    if (this.isLogged()) {
      const data = localStorage.getItem('user');
      return JSON.parse(data).user;
    } else {
      return false;
    }
  }




  getToken(): string {
    return localStorage.getItem('token');
  }

  logout(): boolean {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    return true;
  }


}
