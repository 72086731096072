import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Model} from '../main/model/model.model';
import {Observable} from 'rxjs';

import {environment as env} from '../../environments/environment';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';


@Injectable({
    providedIn: 'root'
})
/**
 * Servicio que obtiene y gestiona las categorías de producto
 */
export class ModelService {

    /**
     * Constructor
     *
     * @param {HttpClient} httpClient
     */
    filter: any;

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getTranslations(sku): Observable<any> {

        let params = new HttpParams();
        let filter = {
            where: {
                skuList: {inq: [sku]}
            }
        }
        params = params.append('filter', JSON.stringify(filter));

        return this.httpClient.get<any>(`${env.apiUrl}/translations`, {params});
    }

    getModel(id): Observable<any> {
        return this.httpClient.get<any>(`${env.apiUrl}/modelos/${id}`);
    }

    getModelWithLang(id, lang, isV5?): Observable<any> {
        return this.httpClient.get<any>(`${env.apiUrl}/indexadoDocs/models/id/${id}${lang ? `/lang/${lang}` : ''}${isV5 ? `/isV5/${isV5}` : '' }`);
        // return this.httpClient.get<any>(`${env.apiUrl}/indexadoDocs/models/id/${id}${lang ? `/lang/${lang}` : ''}${isV5 ? `/isV5/${true}` : ''}`);
    }

    createModelWithLang(id, lang): Observable<any> {
        console.log('id :', id);
        console.log('lang :', lang);
        return this.httpClient.post<any>(`${env.apiUrl}/indexadoDocs/models/id/${id}/translation`, {
            to: lang
        });
    }

    saveModelv2(modelId, lang, indexado, isV5): Observable<any> {
        return this.httpClient.post(`${env.apiUrl}/indexadoDocs/models/id/${modelId}?lang=${lang}&isV5=${isV5}`, {indexado: indexado});
        // return this.httpClient.post(`${env.apiUrl}/indexadoDocs/models/id/${modelId}?lang=${lang}`, {indexado: indexado});
    }

    saveModel(modelId, indexado): Observable<any> {
        return this.httpClient.post(`${env.apiUrl}/modelos/saveIndexado`, {id: modelId, indexado: indexado});
    }



    addModel(modelData): Observable<any> {

        return this.httpClient.post(`${env.apiUrl}/modelos`, modelData);
    }


    getModelsWithNewIndexado(sku = null): Observable<any> {
        let params = new HttpParams();
        let filter = {
            limit: 5,
            fields: ['sku', 'id', 'lang','modelId','defaultLang','indexado_version'],
            order: 'id DESC',
            where: {
                state: {
                    neq: false
                }
            }
        }

        if (sku !== null) {
            filter.where['or'] = [
                {
                    sku: {
                        like: '.*' + sku + '.*', options: 'igm'
                    }
                }

            ];
        }
        console.log('Es aquí');
        params = params.append('filter', JSON.stringify(filter));
        return this.httpClient.get<any[]>(`${env.indexadoApiUrl}/indexadoDocs/getSkus/${sku}`)
    }

    updateModel(model): Observable<any> {
        return this.httpClient.patch(`${env.apiUrl}/modelos/${model.id}`, model);
    }

    getModels(searchValue = null, clientId): Observable<any[]> {
        let params = new HttpParams();
        this.filter = {
            limit: 100,
            include: ['categoria'],
            order: 'created DESC',
            where: {
                state: {
                    neq: false
                },

                version: {
                    neq: 'HPCONNECT4'
                }
            }
        };
        if(clientId == 'HP'){
            this.filter.where.clientId = 'HP'
        }else {
            this.filter.where.clientId = clientId;
        }
        if (searchValue !== null) {
            this.filter.where.or = [
                {
                    'langs.esCL.nombre': {
                        like: '.*' + searchValue + '.*', options: 'igm'
                    }
                },
                {
                    'langs.esCL.name': {
                        like: '.*' + searchValue + '.*', options: 'igm'
                    }
                },
                {
                    'langs.PT.nombre': {
                        like: '.*' + searchValue + '.*', options: 'igm'
                    }
                },
                {
                    sku: {
                        like: '.*' + searchValue + '.*', options: 'igm'
                    }
                },

                {
                    'langs.PT.eanString': {
                        like: '.*' + searchValue + '.*', options: 'igm'
                    }
                },
                {
                    'langs.esCL.eanString': {
                        like: '.*' + searchValue + '.*', options: 'igm'
                    }
                }
            ];
        }

        params = params.append('filter', JSON.stringify(this.filter));
        return this.httpClient.get<any[]>(`${env.apiUrl}/modelos`, {params: params});
    }


}
