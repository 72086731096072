import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {CreateCategoryComponent} from './create/create.category.component';
import {ListCategoryComponent} from './list/list.category.component';
import {EditCategoryComponent} from './edit/edit.category.component';
import {MatPaginatorModule} from '@angular/material/paginator';

// import { RouterModule, Routes } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRippleModule} from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckbox, MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const routes = [

    {
        path: 'category/create',
        component: CreateCategoryComponent
    },
    {
        path: 'category/list',
        component: ListCategoryComponent
    },
    {
        path: 'category/edit/:id',
        component: EditCategoryComponent
    }
];

@NgModule({
    declarations: [
        CreateCategoryComponent,
        ListCategoryComponent,
        EditCategoryComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatTableModule,
        MatDialogModule,
        MatToolbarModule,
        MatRippleModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSlideToggleModule

    ],
    entryComponents: [
    ]
})
export class CategoryModule {
}
