import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Model} from '../main/model/model.model';
import {Observable} from 'rxjs';

import {environment as env} from '../../environments/environment';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';


@Injectable({
    providedIn: 'root'
})
/**
 * Servicio que obtiene y gestiona las categorías de producto
 */
export class CapService {

    /**
     * Constructor
     *
     * @param {HttpClient} httpClient
     */
    filter: any;

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getCap(id): Observable<any> {
        return this.httpClient.get<any>(`${env.apiUrl}/caps/${id}`);
    }


    addCap(cap): Observable<any> {
        return this.httpClient.post<any>(`${env.apiUrl}/caps`,cap);
    }

    updateCap(cap): Observable<any> {
        debugger;
        return this.httpClient.patch(`${env.apiUrl}/caps/${cap.id}`, cap);
    }

    deleteCap(cap): Observable<any> {
        return this.httpClient.delete(`${env.apiUrl}/caps/${cap.id}`);
    }

    getList(searchValue = null): Observable<any[]> {
        let params = new HttpParams();
        this.filter = {
            limit: 100,
            order: 'created DESC',
            where: {
                type : {
                    $exists: false
                }
            },
        };
        if (searchValue !== null) {
            this.filter.where.or = [
                {
                    sku: {
                        like: '.*' + searchValue + '.*', options: 'igm'
                    }
                }
            ];
        }

        params = params.append('filter', JSON.stringify(this.filter));
        return this.httpClient.get<any[]>(`${env.apiUrl}/caps`, {params: params});
    }



}
