import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModelService} from '../../../services/model.service';
import {MatDialog} from '@angular/material/dialog';
import {fuseAnimations} from '@fuse/animations';
import {FormGroup} from '@angular/forms';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, mergeMap} from 'rxjs/operators';
import {BroadcastService} from '../../../services/bradcast.services';
import {ClientService} from '../../../services/client.service';
import {CapService} from '../../../services/cap.service';
import {User} from '../../user/user.model';
import {InputMultimediaComponent} from '../../../multimedia/input.multimedia/input.multimedia.component';
import * as _ from 'lodash';
import { ModalService } from 'app/services/modal.service';
import { INFO_ENTRY_WONT_SINCRONIZE, ERROR_DELETE_ENTRY, ERROR_GET_CATEGORIES_LIST } from 'app/Messages';

@Component({
    selector: 'list.model',
    templateUrl: './list.cap.component.html',
    styleUrls: ['./list.cap.component.scss'],
    animations: fuseAnimations
})
export class ListCapComponent implements OnInit {

    displayedColumns: string[] = ['sku', 'sync', 'state','buttons'];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    public title: string;
    loading: boolean;
    public skus: any[];
    client;
    clientId;
    subscription;
    searchTextChanged = new Subject<string>();
    /**
     * Constructor
     */
    constructor(
        private modelService: ModelService,
        public _matDialog: MatDialog,
        public router: Router,
        private fuseProgressBarService: FuseProgressBarService,
        private broadcastService: BroadcastService,
        private _clientService: ClientService,
        private _capService: CapService,
        private modalDialogService: ModalService
    ) {
        this.loading = true;
        this.fuseProgressBarService.show();
        this.clientId = this._clientService.getCurrentClient().clientId;

        this._capService.getList().subscribe((data) => {
            this.skus = data;
            _.forEach(this.skus, (sku)=>{
                sku.sync = sku.sync ? (new Date(sku.sync)).toLocaleString() : 'No Sincronizado';
            })
            this.dataSource = new MatTableDataSource(this.skus);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loading = false;
        }, (err) => {
            this.modalDialogService.selectModalType('ERRORMODAL', {
                message: ERROR_GET_CATEGORIES_LIST,
                error: err
            })
        });


    }



    ngOnInit(): void {
        this.title = 'Lista de SKUs CAP';
        this.subscription = this.searchTextChanged.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            mergeMap(search => this._capService.getList(search))
        ).subscribe((data) => {
            this.skus = data;
            _.forEach(this.skus, (sku)=>{
                sku.sync = sku.sync ? (new Date(sku.sync)).toLocaleString() : 'No Sincronizado';
            })
            this.dataSource = new MatTableDataSource(this.skus);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loading = false;
            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        });
    }

    deleteCap(cap: any): void {
        this._capService.deleteCap(cap).subscribe((data) => {
            this.modalDialogService.selectModalType('INFOMODAL', INFO_ENTRY_WONT_SINCRONIZE)
        }, (err) => {
            console.error('error', err);
            this.modalDialogService.selectModalType('ERRORMODAL', {
                message: ERROR_DELETE_ENTRY,
                error: err
            })
        });
    }
  /**
     * Edit model
     *
     * @param model
     */
    editCap(cap): void {
        this.router.navigateByUrl('/cap/edit/' + cap.id);
    }

    redirectHotspots(cap): void {
        this.router.navigateByUrl(`/indexado/${cap.sku}/hotspots`);
    }    

}
