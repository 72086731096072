import { Component, OnInit, Input } from '@angular/core';
import { IHotSpot } from '../hotspots.model';

@Component({
  selector: 'app-button-play',
  templateUrl: './button-play.component.html',
  styleUrls: ['./button-play.component.scss']
})
export class ButtonPlayComponent implements OnInit {

  @Input() config: IHotSpot;
  @Input() selected: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  setStylesCircle(): Object {
    if (this.selected) return {fill: this.config.colorIcon}
    return {fill: this.config.backgroundIcon}
  }

  setStylesSvgImg(): Object {
    if (this.selected) return { fill: this.config.backgroundIcon }
    return {fill: this.config.colorIcon}
  }

}
