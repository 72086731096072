import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../main/user/user.model';
import { Observable } from 'rxjs';

import { environment as env } from '../../environments/environment';

export interface ProfileType {
  name: string;
  APP: string;
  identifier: string;
}

@Injectable({
  providedIn: 'root'
})
/**
 * Servicio que obtiene y gestiona los usuarios locales de artefacto inflow-api
 */
export class UserService {

  /**
   * Constructor
   *
   * @param {HttpClient} httpClient
   */
  constructor(
    private httpClient: HttpClient,
  ) { }

  getUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${env.apiUrl}/user`);
  }

  addUser(user: User): Observable<User> {
    return this.httpClient.post<User>(`${env.apiUrl}/user`, user);
  }

  updateUser(user): Observable<any> {
    return this.httpClient.patch(`${env.apiUrl}/user/${user.username}`, user);
  }

  deleteUser(user: User): Observable<any> {
    return this.httpClient.delete(`${env.apiUrl}/user/${user.username}`);
  }

  getProfiles(): Observable<ProfileType[]> {
    return this.httpClient.get<ProfileType[]>(`${env.apiUrl}/profiles`);
  }



}
