// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let currEnv;
if(window.location.hostname.indexOf('production') > -1) {
    currEnv = 'production'
}else{
    currEnv = 'staging'
}

export const environment = {
    production: false,
    hmr: false,
    // apiUrl: `http://localhost:3000/api`,
    apiUrl: `https://api.repositorio.${currEnv}.alquimio.cloud/api`,
    //apiCapUploadUrl : `https://api.repositorio.${currEnv}.alquimio.cloud/api/containers/cap/upload`,
    apiCapUploadUrl : `https://api.repositorio.${currEnv}.alquimio.cloud/api/containers/cap/upload`,
    apiFilesUrl : 'https://api.document.staging.alquimio.cloud/v1/images/upload',
    // featureUrl: `https://product-feature-service.${currEnv}.alquimio.cloud`,
    featureUrl: `https://product-feature-service.${currEnv}.alquimio.cloud`,
    // featureUrl: `http://localhost:3000`,
    indexadoUrl: `https://front.indexado.${currEnv}.alquimio.cloud`,
    // indexadoApiUrl: `http://localhost:3001`,
    indexadoApiUrl: `https://indexado-service.${currEnv}.aks.alquimio.cloud`,
    azureImagesURL: `https://files.alquimio.cloud`,
    azureImagesContainer: `imagenes`,
};
