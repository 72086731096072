import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotspotService } from 'app/services/hotspots/hotspot.service';
import { ModalService } from 'app/services/modal.service';
import { ERROR_GET_SKU_LIST, ERROR_SAVE_INFO, INFO_SAVED_SUCCESSFULLY } from 'app/Messages';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';



/**
 * @title Table with pagination
 */
@Component({
  selector: 'app-hotspots-bulkload',
  styleUrls: ['modal-bulkload-hotspots.component.scss'],
  templateUrl: 'modal-bulkload-hotspots.component.html',
})
export class ModalBulkloadHotspotsComponent implements AfterViewInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  skuCtrl = new FormControl();
  filteredSkus: Observable<string[]>;
  skus: string[] = [];
  allSkus: string[] = [];
  private version: string;
  private baseSku: string;
  private language: string;
  private isLoading: boolean;
  public processHaveSkus:boolean = false
  public ELEMENT_DATA: SkuElement[] = [];
  private publishAll:boolean;
  retail:string;
  targetContainer: string;
  targetContainerType: string;
  isTarget:boolean = false;

  @ViewChild('skuInput') skuInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<ModalBulkloadHotspotsComponent>,
    private hotSpotsService: HotspotService,
    private modalDialogService: ModalService,
    private fuseProgressBarService: FuseProgressBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.filteredSkus = this.skuCtrl.valueChanges.pipe(
      // startWith(null),
      map((sku: string | null) => (sku ? this._filter(sku) : this.allSkus.slice())),
    );
    this.version = data.version
    this.baseSku = data.baseSku
    this.language = data.language
    this.isLoading = false
    this.publishAll = false
  }

  displayedColumns: string[] = ['code', 'exist', 'publish'];
  dataSource = new MatTableDataSource<SkuElement>(this.ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  add(event: MatChipInputEvent): void {
    let values= event.value.split(',')
    if (Array.isArray(values)){
      if(values.length > 0){
        values.forEach(element => {
          if ((element || '').trim()){
            this.skus.push((element || '').trim())
            this.processHaveSkus = false
          }
        });
      }

    }else{
      const value = (event.value || '').trim();
      if (value) {
        this.skus.push(value);
        this.processHaveSkus = false
      }
    }

    if (event.input) {
      event.input.value = '';
    }

    this.skuCtrl.setValue(null);
    this.skus.forEach(element => {
      const findSkus = this.ELEMENT_DATA.find(sku => sku.code === element)
      if (!findSkus) {
        this.ELEMENT_DATA.push({status:undefined,code:element,exist:undefined,publish:false})
      } 
    });
    this.validateAll()
    this.dataSource._updateChangeSubscription()
  }

  async getCheckbox() {
    if ( this.ELEMENT_DATA.filter((i) => i.publish === true).length> 0){
      this.processHaveSkus = true
    }else{
      this.processHaveSkus = false
    }
    this.validateAll()
    this.dataSource._updateChangeSubscription()

  }

  validateAll(){
    const elementsToPublish = this.ELEMENT_DATA.filter((i) => i.publish === true).length
    const totalElements = this.ELEMENT_DATA.length
    this.publishAll = elementsToPublish === totalElements
  }
  async changeChkState(id, prop) {
    this.ELEMENT_DATA.forEach((chk) => {
      if (chk.code === id || id==='all') {
        switch (prop) {
          case 'publish':
            // chk.publish=!chk.publish
            break;
          case 'publishAll':
            if(chk.exist){
              chk.publish=!chk.publish
            }
            break;
          default:
            break;
        }
        this.getCheckbox()
      }
    });
  }
  remove(fruit: string): void {
    const index = this.skus.indexOf(fruit);
    const indexE = this.ELEMENT_DATA.map(function(e) { return e.code; }).indexOf(fruit);
    if (index >= 0) {
      this.skus.splice(index, 1);
      if (indexE >= 0) {
        this.ELEMENT_DATA.splice(index, 1);
        const val = this.ELEMENT_DATA.filter((i) => i.publish === true);
        // console.log('val::::::',val)
        if (val.length > 0){
          this.processHaveSkus = true
        }else{
          this.processHaveSkus = false
        }
        this.validateAll()
        this.dataSource._updateChangeSubscription()
      }
  
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.skus.push(event.option.viewValue);
    this.skuInput.nativeElement.value = '';
    this.skuCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allSkus.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }
  onSearch() {
    // this.fuseProgressBarService.show();
    this.isLoading = true;
    const payload: any = {
      skuList: this.ELEMENT_DATA.map(item => item.code),
      language: this.language
    }
    this.hotSpotsService.searchSkus(payload)
    .subscribe((response:{status:number, data:SkuElement[]}) => {
        if (response.status === 200 && response.data) {
          response.data.forEach(element => {
            let index = this.ELEMENT_DATA.map(function(e) { return e.code; }).indexOf(element.code);
            if(index >= 0){
              this.ELEMENT_DATA[index].exist= element.exist
              this.ELEMENT_DATA[index].status= element.status
            }
          });
          this.dataSource._updateChangeSubscription()
        } else {
          this.modalDialogService.selectModalType('ERRORMODAL', {
            message: ERROR_GET_SKU_LIST,
            error: 'Error consultando la información'
          });
        }
        this.isLoading = false;
      },
      (err: any) => {
        this.isLoading = false;
        this.fuseProgressBarService.hide();
        this.modalDialogService.selectModalType('ERRORMODAL', {
            message: ERROR_GET_SKU_LIST,
            error: err
        })
      });
  }

  onBulkLoad(){
    this.isLoading = true;
    const payload: any = {
      baseSku:{
        language: this.language,
        sku: this.baseSku,
        version: this.version
      },
      skuList: this.ELEMENT_DATA.filter((i) => i.publish === true).map(item => item.code),
    }
    if (this.retail!== ''){
      Object.assign(payload,{targetRetail:this.retail})
    }
    if (this.targetContainer!== ''){
      Object.assign(payload,{targetContainer:this.targetContainer})
    }
    if (this.targetContainerType!== ''){
      Object.assign(payload,{targetContainerType:this.targetContainerType})
    }
    this.hotSpotsService.saveBulkloadHotspots(payload)
    .subscribe((response:{status:number, data:any}) => {
        if (response.status === 200 && response.data) {
          if (response.data.fallido.length <= 0){
            this.modalDialogService.selectModalType('INFOMODAL', INFO_SAVED_SUCCESSFULLY);
            this.dialogRef.close()
          }else{
            // TODO: aqui se debe usar confirm para llamar de nuevo a onBulkLoad con la data faltante
            console.log('Códigos fallidos::::::::',response.data.fallido)
            this.modalDialogService.selectModalType('ERRORMODAL', {
              message: ERROR_SAVE_INFO,
              error: 'Error guardando la información'
            });
          }
        } else {
          this.modalDialogService.selectModalType('ERRORMODAL', {
            message: ERROR_SAVE_INFO,
            error: 'Error en servidor al guardar información'
          });
        }
        this.isLoading = false;
      },
      (err: any) => {
        this.isLoading = false;
        this.fuseProgressBarService.hide();
        this.modalDialogService.selectModalType('ERRORMODAL', {
            message: ERROR_SAVE_INFO,
            error: err
        })
      });
  }
}

export interface SkuElement {
  code: string;
  exist: boolean;
  publish: boolean;
  status: boolean;
}

