import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import {IndexadoComponent} from './indexado.component';
import {FuseProgressBarModule, FuseSearchBarModule, FuseSidebarModule} from '@fuse/components';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {IndexadoSidebarComponent} from './indexado-sidebar/indexado-sidebar.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FuseMaterialColorPickerModule} from '../../../@fuse/components/material-color-picker/material-color-picker.module';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {MatSliderModule} from '@angular/material/slider';
import {EditCategoryComponent} from '../category/edit/edit.category.component';
import {SlideshowModule} from 'ng-simple-slideshow';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import { VideoComponent } from './indexado-sidebar/components/video/video.component';
import {InputMultimediaComponent} from '../../multimedia/input.multimedia/input.multimedia.component';
import {MultimediaDialogComponent} from '../../multimedia/dialog/multimedia-dialog.component';
import { MatFileUploadModule } from 'angular-material-fileupload';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import { ButtonComponent } from './indexado-sidebar/components/button/button.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { HotspotsComponent } from './hotspots/hotspots.component';
import { HotspotsSidebarComponent } from './hotspots/hotspots-components/hotspots-sidebar/hotspots-sidebar.component';
import { SectionGeneralComponent } from './hotspots/hotspots-components/hotspots-sidebar/section-general/section-general.component';
import { SectionSettingComponent } from './hotspots/hotspots-components/hotspots-sidebar/section-setting/section-setting.component';
import { SectionVideoComponent } from './hotspots/hotspots-components/hotspots-sidebar/section-video/section-video.component';
import { SectionKspComponent } from './hotspots/hotspots-components/hotspots-sidebar/section-ksp/section-ksp.component';
import { SectionTextComponent } from './hotspots/hotspots-components/hotspots-sidebar/section-text/section-text.component';
import { SectionGaleryComponent } from './hotspots/hotspots-components/hotspots-sidebar/section-galery/section-galery.component';
import { HeaderVersionsComponent } from './hotspots/hotspots-components/header-versions/header-versions.component';
import { FooterControlsComponent } from './hotspots/hotspots-components/footer-controls/footer-controls.component';
import { HotspotsPreviewComponent } from './hotspots/hotspots-preview/hotspots-preview.component';
import { ButtonPlayComponent } from './hotspots/button-play/button-play.component';
import { ButtonInfoComponent } from './hotspots/button-info/button-info.component';
import { ButtonGalleryComponent } from './hotspots/button-gallery/button-gallery.component';
import { ButtonTextComponent } from './hotspots/button-text/button-text.component';
import { ButtonEmptyComponent } from './hotspots/button-empty/button-empty.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ModalDialogConfirmation } from './hotspots/hotspots-components/modalTypes/modal-confirm.component';
import { ModalDialogError } from './hotspots/hotspots-components/modalTypes/modal-error.component';
import { ModalDialogInfo } from './hotspots/hotspots-components/modalTypes/modal-info.component';
import { DropdownInvalidValToStringPipe } from 'app/dropdown-void-val-to-string.pipe';
import { HotspotModalPreviewComponent } from './hotspots/hotspots-components/hotspot-modal-preview/hotspot-modal-preview.component';
import { ContentTextComponent } from './hotspots/hotspots-components/hotspot-modal-preview/content-text/content-text.component';
import { ContentVideoComponent } from './hotspots/hotspots-components/hotspot-modal-preview/content-video/content-video.component';
import { ContentKspComponent } from './hotspots/hotspots-components/hotspot-modal-preview/content-ksp/content-ksp.component';
import { ContentGalleryComponent } from './hotspots/hotspots-components/hotspot-modal-preview/content-gallery/content-gallery.component';
import { StandardBtnComponent } from '../../transversal-components/buttons/standard-btn/standard-btn.component';
import { ModalAddKspComponent } from './hotspots/hotspots-components/modalTypes/modal-add-ksp/modal-add-ksp.component';
import { ModalEditKSPComponent } from './hotspots/hotspots-components/modalTypes/modal-edit-ksp/modal-edit-ksp.component';
import { ModalEditTEXTComponent } from './hotspots/hotspots-components/modalTypes/modal-edit-text/modal-edit-text.component';
import { ModalEditGALLERYComponent } from './hotspots/hotspots-components/modalTypes/modal-edit-gallery/modal-edit-gallery.component';
import { ModalBulkloadHotspotsComponent } from './hotspots/hotspots-components/modalTypes/modal-bulkload-hotspots/modal-bulkload-hotspots.component';
import { ModalAddNewElementComponent } from './hotspots/hotspots-components/modalTypes/modal-add-new-element/modal-add-new-element.component';
import { CustomVersionNameFilterPipe } from './indexado-custom-name-pipe';
import { ModalSelectFormatHotspotsComponent } from './hotspots/hotspots-components/modalTypes/modal-select-format-hotspots/modal-select-format-hotspots.component'
import { ModalRetailConfigHotspotsComponent } from './hotspots/hotspots-components/modalTypes/modal-retail-config/modal-retail-config-hotspots.component';

const routes = [
    {
        path: 'indexado/:id',
        component: IndexadoComponent
    },
    {
        path: 'indexado',
        component: IndexadoComponent
    },
    {
        path: 'indexado/:id/hotspots',
        component: HotspotsComponent
    },
    {
        path: 'indexado/:id/hotspots/preview',
        component: HotspotsPreviewComponent
    },
];

@NgModule({
    declarations: [
        IndexadoComponent,
        IndexadoSidebarComponent,
        VideoComponent,
        InputMultimediaComponent,
        MultimediaDialogComponent,
        ButtonComponent,
        IndexadoSidebarComponent,
        HotspotsComponent,
        HotspotsSidebarComponent,
        SectionGeneralComponent,
        SectionSettingComponent,
        SectionVideoComponent,
        SectionKspComponent,
        SectionTextComponent,
        SectionGaleryComponent,
        HeaderVersionsComponent,
        FooterControlsComponent,
        HotspotsPreviewComponent,
        ButtonPlayComponent,
        ButtonInfoComponent,
        ButtonGalleryComponent,
        ButtonTextComponent,
        ButtonEmptyComponent,
        ModalDialogConfirmation,
        ModalDialogError,
        ModalDialogInfo,
        DropdownInvalidValToStringPipe,
        HotspotModalPreviewComponent,
        ContentTextComponent,
        ContentVideoComponent,
        ContentKspComponent,
        ContentGalleryComponent,
        StandardBtnComponent,
        ModalEditKSPComponent,
        ModalEditTEXTComponent,
        ModalBulkloadHotspotsComponent,
        ModalAddNewElementComponent,
        ModalAddKspComponent,
        ModalEditGALLERYComponent,
        CustomVersionNameFilterPipe,
        ModalSelectFormatHotspotsComponent,
        ModalRetailConfigHotspotsComponent
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        RouterModule.forChild(routes),
        FuseSidebarModule,
        TranslateModule,
        FuseDemoModule,
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatInputModule,
        FuseMaterialColorPickerModule,
        MatStepperModule,
        MatSelectModule,
        YouTubePlayerModule,
        SlideshowModule,
        MatSliderModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        FuseSearchBarModule,
        FuseProgressBarModule,
        MatTooltipModule,
        MatFileUploadModule,
        MatTabsModule,
        MatDialogModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatChipsModule,
        MatProgressSpinnerModule,
    ],
    exports     : [
        CustomVersionNameFilterPipe,
        IndexadoComponent,
        InputMultimediaComponent
    ],

})

export class IndexadoModule
{
    // kong-docker-kubernetes-ingress-controller.bintray.io/kong-ingress-controller
}
