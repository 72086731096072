import { Component, OnInit, ViewChild } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import { CampaignService } from '../../../../services/campaign.service';
import { ICampaign } from '../../project.model';
import * as moment from 'moment';
import { ModalService } from '../../../../services/modal.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'startDate', 'endDate', 'utc', 'buttons'];
  dataSource: MatTableDataSource<any>;
  campaigns: ICampaign[];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  loading: boolean;
  constructor(
    public router: Router,
    public campaignService: CampaignService,
    private modalService: ModalService
  ) {}  

  ngOnInit(): void {
    this.campaignService.index().subscribe((data: {data: ICampaign[]}) => {
      console.log('Recibe :', data);
      this.campaigns = data.data;
      this.dataSource = new MatTableDataSource(this.campaigns);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
    
  }

  editCampaign(campaign: ICampaign): void {
    this.router.navigateByUrl('/project/indexado/edit/' + campaign.campaignId);
  }

  deleteCampaign(campaign: ICampaign): void {
    this.modalService.selectModalType('BUTTONCONFIRM','Esta seguro de eliminar esta campaña?', (resp: any) => {
      if (resp) {
        this.campaignService.delete(campaign.campaignId).subscribe((data: {status: number}) => {
          console.log('Delete:', data, campaign)
          if (data.status === 200) {
            this.campaigns = this.campaigns.filter((c: ICampaign) => c.campaignId !== campaign.campaignId)
            this.dataSource = new MatTableDataSource(this.campaigns);
          }
        });  
      }
    })
  }

  calculateColor(campaign: ICampaign): string {
    // banner.endTimeFormated = moment.tz(banner.endTime, banner.timeZone).format('D-MM-YYYY, hh:mm');
    var endTime2 = moment.tz(campaign.endDate, campaign.utc);
    var now2 = moment.tz(campaign.utc);
    var diff = endTime2.diff(now2, 'hours');
    if (diff <= 0) {
      return 'red-date-form';
    } else if (diff > 0 && diff < 336) {
      return 'orange-date-form';
    } else if (diff >= 336) {
      return 'green-date-form'
    }

  }
}
