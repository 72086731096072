import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {CategoryService} from '../../../services/category.service';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

import {ActivatedRoute, Router} from '@angular/router';
import {Category} from '../category.model';
import {ClientService} from '../../../services/client.service';

@Component({
    selector: 'edit.category',
    templateUrl: './edit.category.component.html',
    styleUrls: ['./edit.category.component.scss']
})
export class EditCategoryComponent implements OnInit, OnDestroy {

    public title: string;
    category: any;
    categoryForm: FormGroup;
    errorMessage: string;
    id: string;
    // Private
    private _unsubscribeAll: Subject<any>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    clientId: string;

    /**
     * Constructor
     *
     * @param {FormBuilder} _categoryFormBuilder
     * @param categoryService
     * @param fuseProgressBarService
     * @param _snackBar
     * @param router
     */
    constructor(
        private _formBuilder: FormBuilder,
        private categoryService: CategoryService,
        private fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private _clientService: ClientService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // Reactive Form


    ngOnInit(): void {
        this.title = 'Crear Categoría';
        this.fuseProgressBarService.setMode('indeterminate');
        this.clientId = this._clientService.getCurrentClient().clientId;

        this.id = this.route.snapshot.paramMap.get('id');
        this.categoryService.getCategory(this.id).subscribe(category => {
            this.category = category;
            this.categoryForm = this._formBuilder.group({
                name: [category.name, Validators.required],
                description: [category.description, Validators.required],
                displayOnExport: [true, Validators.required],
                specs: new FormArray([])
            });

            category.specs.forEach(spec => {
                this.addSpec(spec);
            });

        });


    }

    get f() {
        return this.categoryForm.controls;
    }

    get t() {
        return this.f.specs as FormArray;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onSubmit(): void {
        this.category = this.categoryForm.getRawValue();
        this.category.id = this.id;
        this.category.state = true;
        this.category.clientId = this.clientId;

        this.fuseProgressBarService.show();


        this.categoryService.updateCategory(this.category).subscribe((data: Category )=>{
            this.fuseProgressBarService.hide();
            this.showSnackBar('Categoria Actualizada');
            this.router.navigateByUrl('category/list');
        })
    }

    initSpecRow(label = null, key = null, displayOnExport = null): FormGroup {
        return this._formBuilder.group({
            label: [label, [Validators.required]],
            key: [key, [Validators.required]],
            displayOnExport: [displayOnExport, [Validators.required]]
        });
    }

    removeSpecRow(rowIndex: number): void {
        const specs = <FormArray>this.categoryForm.controls['specs'];
        if (specs.length > 1) {
            specs.removeAt(rowIndex);
        } else {
            this.errorMessage = 'You cannot delete this row! form should contain at least one row!';
            setTimeout(() => {
                this.errorMessage = null;
            }, 4000);
        }
    }


    addSpec(spec): void {
        // console.log('specs:', spec)
        spec = spec == null ? {label:null,key:null,displayExel:false}:spec;
        const specs = <FormArray>this.categoryForm.controls['specs'];
        specs.push(this.initSpecRow(spec.label || null, spec.key || null, spec.displayOnExport || false));
    }

    deleteSpec(index): void {
        this.removeSpecRow(index);
    }

    showSnackBar(message: string): void {
        this._snackBar.open(message, 'Ok', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
    }


}
