import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalDialogDataText } from '../../../hotspots.model';
import { ModelService } from 'app/services/model.service';

@Component({
  selector: 'app-modal-edit-text',
  templateUrl: './modal-edit-text.component.html',
  styleUrls: ['./modal-edit-text.component.scss']
})
export class ModalEditTEXTComponent implements OnInit {
  @Input() sku: string;
  diferenciators: any[];
  title: string;
  description: string;
  // icono: string;
  fromModel: boolean;
  properties: {key: string, value: string}[];
  property: string;
  language:string;

  constructor(
    public dialogRef: MatDialogRef<ModalEditTEXTComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalDialogDataText,
    private modelService: ModelService
  ) {
    this.sku = this.data.sku;
    this.language = this.data.language?this.data.language:'esCL';
    this.modelService.getModels(this.sku, 'HP').subscribe((response: any) => {
      if (response.length > 0) {
        const model = response[0];
        if (model.langs && model.langs[this.language]) {
          if (model.langs[this.language].diferenciadores) {
            this.diferenciators = model.langs[this.language].diferenciadores.map((differ: any) => {
              return differ;
            });
          }
          this.properties = [];
          Object.keys(model.langs[this.language]).forEach((key: string) => {
            if (typeof model.langs[this.language][key] === 'string') {
              this.properties.push({key, value: model.langs[this.language][key]});
            }
          });
        }
      }
      console.log(this.diferenciators);
    },
    (err: any) => {
       this.diferenciators = [];
    });
  }

  ngOnInit(): void {}

  onClick(): void {
    this.dialogRef.close({action: 'save', data: this.data.texts});
  }

  onNoClick(): void {
    this.dialogRef.close({status: 'cancel'});
  }

  onAdd(): void {
    const newElem = {
      title: this.title,
      text: this.fromModel ? this.property : this.description,
      // icono: this.icono,
      fromModel: this.fromModel,
    }
    if(this.data.texts){
      this.data.texts = [...this.data.texts, newElem];
    }else{
      this.data.texts =[newElem]
    }
    this.title = '';
    this.description = '';
    // this.icono = '';
  }

  onDelete(elem: any, i: number) {
    console.log("elem::",elem);
    this.data.texts.splice(i,1);
    // this.data.texts = this.data.texts.filter((e: any) => e.title !== elem.title);
  }

  onChangeFromModel(e: any) {
    this.fromModel = e.checked;
  }
} 
