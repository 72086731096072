import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {User} from '../user.model';
import {MatDialog} from '@angular/material/dialog';
import {UserFormComponent} from '../user-form/user-form.component';
import {fuseAnimations} from '@fuse/animations';
import {FormGroup} from '@angular/forms';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { ModalService } from 'app/services/modal.service';
import { ERROR_DELETE_USER, ERROR_GET_USERS_LIST, ERROR_UPDATE_USER, INFO_DELETED_USER, INFO_UPDATED_USER } from 'app/Messages';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'list.user',
  templateUrl: './list.user.component.html',
  styleUrls: ['./list.user.component.scss'],
  animations: fuseAnimations
})
export class ListUserComponent implements OnInit {

  displayedColumns: string[] = ['name', 'username', 'email', 'buttons'];
  dataSource: MatTableDataSource<User>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public title: string;
  dialogRef: any;
  loading: boolean;
  public users: User[];

  /**
   * Constructor
   */
  constructor(
    private userService: UserService,
    private modalDialogService: ModalService,
    public _matDialog: MatDialog
  ) {
    this.loading = true;
    this.userService.getUsers().subscribe((data) => {
      this.users = data;
      this.dataSource = new MatTableDataSource(this.users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;

    }, (err) => {
      this.modalDialogService.selectModalType('ERRORMODAL', {
          message: ERROR_GET_USERS_LIST,
          error: err
      })
    });
  }

  ngOnInit(): void {
    this.title = 'Lista de Usuarios';


  }

  updateListUsers(): void {
    this.userService.getUsers().subscribe((data) => {
      this.users = data;
      this.dataSource = new MatTableDataSource(this.users);

    }, (err) => {
      this.modalDialogService.selectModalType('ERRORMODAL', {
          message: ERROR_GET_USERS_LIST,
          error: err
      })
    });
  }

  applyFilter(filterValue: string): any {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteUser(user: User): void {

    this.userService.deleteUser(user).subscribe((data) => {
      this.modalDialogService.selectModalType('INFOMODAL', INFO_DELETED_USER)
      this.updateListUsers();
    }, (err) => {
      console.error('error', err);
      this.modalDialogService.selectModalType('ERRORMODAL', {
          message: ERROR_DELETE_USER,
          error: err
      })
    });

  }

  /**
   * Show user
   *
   * @param user
   */
  showUser(user): void {
    this.dialogRef = this._matDialog.open(UserFormComponent, {
      panelClass: 'user-form-dialog',
      data: {
        user: user,
        action: 'show'
      }
    });
  }

  /**
   * Edit user
   *
   * @param user
   */
  editUser(user): void {
    this.dialogRef = this._matDialog.open(UserFormComponent, {
      panelClass: 'user-form-dialog',
      data: {
        user: user,
        action: 'edit'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        const actionType: string = response[0];
        const formData: FormGroup = response[1];
        switch (actionType) {
          /**
           * Save
           */
          case 'save':
            let dataUser = formData.getRawValue();
            debugger;
            dataUser.profiles = {
              NC: dataUser.profilesNC,
              CLI: dataUser.profilesCLI
            };
            delete dataUser.profilesNC;
            delete dataUser.profilesCLI;
            this.userService.updateUser(dataUser).subscribe(() => {
              this.modalDialogService.selectModalType('INFOMODAL', INFO_UPDATED_USER)
              this.updateListUsers();
            }, (err) => {
              this.modalDialogService.selectModalType('ERRORMODAL', {
                  message: ERROR_UPDATE_USER,
                  error: err
              })
            });

            break;
          /**
           * Delete
           */
          case 'delete':

            this.deleteUser(user);
            this.updateListUsers();
            break;
        }
      });
  }


}
