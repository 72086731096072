import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {CreateUserComponent} from './create/create.user.component';
import {ListUserComponent} from './list/list.user.component';
import {EditUserComponent} from './edit/edit.user.component';
import {MatPaginatorModule} from '@angular/material/paginator';

// import { RouterModule, Routes } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRippleModule} from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import { UserFormComponent } from './user-form/user-form.component';
import {MatCheckbox, MatCheckboxModule} from '@angular/material/checkbox';

const routes = [

  {
    path: 'user/create',
    component: CreateUserComponent
  },
  {
    path: 'user/list',
    component: ListUserComponent
  },
  {
    path: 'user/edit',
    component: EditUserComponent
  }

];

@NgModule({
  declarations: [
    CreateUserComponent,
    ListUserComponent,
    EditUserComponent,
    UserFormComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTableModule,
    MatDialogModule,
    MatToolbarModule,
    MatRippleModule,
    MatPaginatorModule,
    MatCheckboxModule

  ],
  entryComponents: [
    UserFormComponent
  ]
})
export class UserModule {
}
