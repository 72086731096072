import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IHotSpot, HOTSPOT_TYPES, IHotSpotAdditionalAttr } from '../../../hotspots.model';
@Component({
  selector: 'app-section-setting',
  templateUrl: './section-setting.component.html',
  styleUrls: ['./section-setting.component.scss']
})
export class SectionSettingComponent implements OnInit {

  @Input() currentHotspot: IHotSpot;
  @Output() deleteHotspot = new EventEmitter();
  @Output() duplicateHotspot = new EventEmitter();
  @Output() changeHotspotConfig = new EventEmitter<IHotSpotAdditionalAttr>();
  @Output() changeLayoutHotspot = new EventEmitter<IHotSpotAdditionalAttr>();

  private hotspotTypes: HOTSPOT_TYPES[];
  private isOpen = true

  constructor() { }

  ngOnInit(): void {
    this.hotspotTypes = [
      HOTSPOT_TYPES.EMPTY,
      HOTSPOT_TYPES.GALLERY,
      HOTSPOT_TYPES.KSP,
      HOTSPOT_TYPES.TEXT,
      HOTSPOT_TYPES.VIDEO
    ];
  }

  onDuplicateHotspot(): void {
    this.duplicateHotspot.emit();
  }
  onDeleteHotspot(): void {
    this.deleteHotspot.emit();
  }

  onChangeHotspotConfig(event: any, field: string): void {
    this.changeHotspotConfig.emit({
      key: field,
      value: event.target ? event.target.value : event.value
    });
  }
  onChangeLayoutHotspot(event: any, field: string): void {
    this.changeLayoutHotspot.emit({
      key: field,
      value: event.target.value
    });
  }

  onUnselectedHotspot(): void {
      this.currentHotspot = null;
  }
  
  openAccordeonElement(open: boolean): void {
    this.isOpen = open
  }

}
