
interface IScriptParams {
    sku: string,
    lang: string, 
    version: string, 
    urlHotspots: string, 
    urlLogs: string,
    container: {
        id: string,
        type: string
    }
}

export const SCRIPT_TAG = (parms: IScriptParams) => `
<script>
(async () => {
    const close = () => {
        const m = document.getElementById('preview-modal');
        if (m) {
            m.remove();
        }
    }
    const modalHeader = \`
    <div class="share-bubble" 
        style="margin: 137.002px auto auto 17.0128%;font-weight: bold;background-color: #ffffffe6;border: 1px solid grey;
border-radius: 7px;width: auto;height:auto;min-height: 200px;position: relative;max-height: -webkit-fill-available;overflow: scroll;padding: 10px 0;margin: 10% auto;text-align:leftcolor: #000000;font-family: "Open Sans Bold";" 
        id="preview-modal"
    >
        <div class="modalHeader">
            <i class="bi bi-x-circle-fill right" id="closeModal" style="color: black; float: right;"></i>
        </div>
        <div id="asus-hotspot" style="max-width: 640px;">
            <div class="hotspot_block">
                <div class="hotspot_block_title"></div>
                \`

    const modalFooter = \`
            </div>
        </div>
    </div>
    
    \`
    var app;

    const PARAMS = {
        urlHotspots: '${parms.urlHotspots}',
        urlLogs: '${parms.urlLogs}',
        id: '${parms.sku}',
        language: '${parms.lang}',
        version: '${parms.version}'
    }
    let interval;

    const callService = async (url, data) => {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        return response.json();
    }

    const buildhotspot = (hotspot) => {
        switch (hotspot.type) {
            case 'VIDEO':
                return \`<svg style="z-index: 999" class="svg__hotspot--pervasivemind"
                        id="flix_key_features"
                        class="flix_hs flix_hs_key_features flix_hs_hash_1a1a1a"
                        title="\$\{hotspot.text}"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" viewBox="0 0 34 34"
                        xml:space="preserve"
                        width = '\${hotspot.layout.width}'
                        height = '\${hotspot.layout.height}'>
                            <g id="flix_hs_button">
                                <path style.fill='\${hotspot.backgroundIcon}' stroke='\${hotspot.colorIcon}' stroke-width="2" stroke-miterlimit="10" d="M31.75,18c0,8.285-6.715,15-15,15c-8.284,0-15-6.715-15-15c0-8.284,6.716-15,15-15C25.035,3,31.75,9.716,31.75,18z"></path>
                            </g>
                            <g id="flix_hs_play">
                                <g id="flix_hs_play2" display="inline">
                                    <polygon fill='\${hotspot.colorIcon}' points="12,8.667 25,17.333 12,26 "></polygon>
                                </g>
                            </g>
                    </svg>\`
            case 'GALLERY':
                return \`<svg style="z-index: 999" class="svg__hotspot--pervasivemind"
                    id="flix_key_features"
                    class="flix_hs flix_hs_key_features flix_hs_hash_1a1a1a"
                    title="\${hotspot.text}"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px" y="0px" viewBox="0 0 34 34"
                    xml:space="preserve"
                    width = "\${hotspot.layout.width}"
                    height = "\${hotspot.layout.height}"
                    top = "\${hotspot.layout.posY}%"
                    left = "\${hotspot.layout.posX}%"
                    <g id="flix_hs_button">
                        <path style.fill="\${hotspot.backgroundIcon}" stroke="\${hotspot.colorIcon}" stroke-width="2" stroke-miterlimit="10" d="M31.75,18c0,8.285-6.715,15-15,15c-8.284,0-15-6.715-15-15c0-8.284,6.716-15,15-15C25.035,3,31.75,9.716,31.75,18z"></path>
                        </g>
                        <g id="flix_hs_gallery">
                        <g id="flix_hs_gallery2" display="inline">
                            <g>
                                <path fill="\${hotspot.colorIcon}" d="M18.859,16.688c1.01,0,1.828,0.818,1.828,1.828c0,1.01-0.818,1.828-1.828,1.828c-1.01,0-1.828-0.818-1.828-1.828C17.031,17.506,17.85,16.688,18.859,16.688z">
                                </path>
                                <path fill="\${hotspot.colorIcon}" d="M22.516,13.031l-1.503-3.036c-0.123-0.361-0.465-0.621-0.868-0.621h-2.51c-0.408,0-0.752,0.266-0.871,0.633l-1.559,3.023h-3.658c-1.01,0-1.828,0.818-1.828,1.828V24h14.625v-9.141C24.344,13.85,23.526,13.031,22.516,13.031z M12.461,16.688c-0.505,0-0.914-0.409-0.914-0.914c0-0.505,0.409-0.914,0.914-0.914c0.505,0,0.914,0.409,0.914,0.914C13.375,16.279,12.966,16.688,12.461,16.688z M18.859,22.172c-2.02,0-3.656-1.637-3.656-3.656c0-2.019,1.637-3.656,3.656-3.656c2.02,0,3.656,1.637,3.656,3.656C22.516,20.535,20.879,22.172,18.859,22.172z">
                                </path>
                            </g>
                        </g>
                    </g>
                    </svg>\`                
            case 'KSP':
                return \`
                    <svg class="svg__hotspot--pervasivemind"
                        id="flix_key_features"
                        class="flix_hs flix_hs_key_features flix_hs_hash_1a1a1a"
                        title="\${hotspot.text}"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" viewBox="0 0 34 34"
                        xml:space="preserve"
                        width = "\${hotspot.layout.width}"
                        height = "\${hotspot.layout.height}"
                        <g id="flix_hs_button">
                            <path fill="\${hotspot.backgroundIcon}" stroke="\${hotspot.colorIcon}" stroke-width="2" stroke-miterlimit="10" d="M31.75,18c0,8.285-6.715,15-15,15c-8.284,0-15-6.715-15-15c0-8.284,6.716-15,15-15C25.035,3,31.75,9.716,31.75,18z"></path>
                        </g>

                        <g id="flix_hs_info">
                            <g id="flix_hs_info2" display="inline">
                            <g>
                                <path fill="\${hotspot.colorIcon}" d="M14,17c0.553,0,1,0.447,1,1v4c0,0.553-0.447,1-1,1h-1v2h8v-2h-0.996c-0.551,0-1-0.447-1-1L19,15h-6v2H14z">
                                </path>
                                <circle fill="\${hotspot.colorIcon}" cx="17" cy="11" r="2"></circle>
                            </g>
                            </g>
                        </g>
                    </svg>
                    \`
            case 'TEXT':
                return \`
                    <svg class="svg__hotspot--pervasivemind"
                        id="flix_key_features"
                        class="flix_hs flix_hs_key_features flix_hs_hash_1a1a1a"
                        title="\${hotspot.text}"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" viewBox="0 0 34 34"
                        xml:space="preserve"
                        width = "\${hotspot.layout.width}"
                        height = "\${hotspot.layout.height}"
                        top = "\${hotspot.layout.posY}%"
                        left = "\${hotspot.layout.posX}%"
                    >
                            <g id="flix_hs_button">
                                <path fill="\${hotspot.backgroundIcon}" stroke="\${hotspot.colorIcon}" stroke-width="2" stroke-miterlimit="10" d="M31.75,18c0,8.285-6.715,15-15,15c-8.284,0-15-6.715-15-15c0-8.284,6.716-15,15-15C25.035,3,31.75,9.716,31.75,18z"></path>
                            </g>

                            <g id="flix_hs_document">
                                <g id="flix_hs_doc2" display="inline">
                                <path fill="\${hotspot.colorIcon}" d="M18,9h-7v16h12V13.999L18,9z M13,23V11h4v4h4v8H13z"></path>
                                </g>
                            </g>
                    </svg>                    
                    \`
            default:
                return \`\`
            
        }
    }
    let counter = 0;
    const doAnimation = (hotspotsElements) => {
        let action;
        counter ++;
        if (counter < 5) {
            action = 1;
        } else if (counter < 9) {
            action = -1
        } else {
            action = 0;
            counter = 0;
        }
        for (var i=0; i < hotspotsElements.length; i++) {
            hotspotsElements[i].style.width = \`\${hotspotsElements[i].clientWidth + action}px\`;
            hotspotsElements[i].style.height = \`\${hotspotsElements[i].clientHeight + action}px\`;
        }
    }

    const buildModal = (hotspot) => {
        let modalBody = '';
        switch (hotspot.type) {
            case 'VIDEO':
                const videoObj = hotspot.additionalAttr.find(elem => elem.key === 'URL');
                if (videoObj) {
                    modalBody = \`
                        <iframe width=480" height="360" src="\${videoObj.value}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen autoplay></iframe>                    
                        \`;
                }

                break;
            case 'GALLERY':
                const images = hotspot.additionalAttr.find(elem => elem.key === 'IMAGES');
                if (images) {
                    let circles = '';
                    modalBody = \`
                        <div style="font-size: 18px;color: #333;padding: 5px 0 15px;font-family: 'Open Sans Bold';border: 0 !important;font-weight: bold;">\${hotspot.text}</div>
                    
                        \`;
                    modalBody += images.value.map((image, index) => {
                        circles += \`<i class="bi bi-circle-fill gallery-buttons" data-image="\${index}" style="font-size: 24px;width: 24px;height: 24px;min-width: 24px;min-height: 24px;line-height: 24px;color: rgba(0, 0, 0, 0.54);margin: 0px 5px;"></i>\`;
                        const display = index == 0 ? 'block' : 'none';
                        return \`<img src=\${image} style="display: \${display}" class="gallery-img" />\`
                    });
                    if (images.value.length > 1) {
                        modalBody += \`<div class="hotspotsGalleryFooter" style="text-align: center;">
                        \${circles}
                        </div>\`;
                    }

                }
                break;

            case 'KSP':
                modalBody = \`<div>
                <div class="hotspotsKspCarousel">
                  <img style="max-width: 400px" src=""><p _ngcontent-ecn-c311=""></p></div><!--bindings={
                <div class="hotspotsKspFooter">
                  <i class="bi bi-circle-fill"></i>
                </div>
              </div>\`;
                break;
            case 'TEXT':
                const obj = hotspot.additionalAttr.find(elem => elem.key === 'TEXT');
                if (obj) {
                    modalBody = \`
                        <div style="font-size: 18px;color: #333;padding: 5px 0 15px;font-family: 'Open Sans Bold';border: 0 !important;font-weight: bold;max-width: 500px;">\${hotspot.text}</div>
                        <p class="hotspots-text ng-star-inserted">\${obj.value}</p>
                        \`
                }
                break;
            default:
                break;
        }
        return \`\${modalHeader}\${modalBody}\${modalFooter}\`;
    }

    const paginate = (index) => {
        const images = document.getElementsByClassName('gallery-img');
        for (let i = 0;i < images.length; i++) {
            const display = i === index ? 'block' : 'none';
            images[i].style.display = display;
        }
    }

    const open = (hotspot) => {
        close();
        const m = document.createElement('div');
        m.innerHTML = buildModal(hotspot);
        m.style.zIndex = 99999;
        m.style.position = 'absolute';
        m.style.left = \`\${hotspot.layout.posX * 0.5}%\`;
        m.style.top = \`\${hotspot.layout.posY * 0.5}%\`;
        app.append(m);
        document.getElementById('closeModal').addEventListener('click', close);
        const buttons = document.getElementsByClassName('gallery-buttons');
        for(let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', paginate.bind(this, i));
        }
        callService(PARAMS.urlLogs, { message: 'Click on hotspot => SKU:::${parms.sku} version:: ${parms.version} hotspot::\`\${hotspot.type }\`', sku: '${ parms.sku}', type: 'OPEN' })
    }

    setTimeout(async () => {
        try {
            const response = await callService(PARAMS.urlHotspots, { id: PARAMS.id, language: PARAMS.language })
            let hotspots = [];                
            if (response.status !== 200) {
                callService(PARAMS.urlLogs, { message: 'Error consultando los hotspots => SKU:::${parms.sku} version:: ${parms.version} ', sku: '${ parms.sku}' })
                return;
            }

            const currentVersion = response.data.find(version => version.language === PARAMS.language && version.version === PARAMS.version);

            if (!currentVersion) {
                callService(PARAMS.urlLogs, { message: 'Version not found => elemen:::${parms.container.id} on SKU::${parms.sku} version:: ${parms.version}', sku: '${parms.sku}' })
                return;
            }
            hotspots = currentVersion.hotspots;                
            app = currentVersion.containerType === 'id' ? document.getElementById(currentVersion.container) : document.querySelector('.' + currentVersion.container)[0];
            if (!app) {
                callService(PARAMS.urlLogs, { message: 'HTML ELement not found => elemen:::\`\${currentVersion.container}\` on SKU::${parms.sku} version:: ${parms.version}', sku:'${parms.sku}' })
                return;
            }
            hotspots.forEach(hotspot => {
                const sp = document.createElement('span');
                sp.style.display = 'flex';
                sp.style.width = '100%';
                sp.style.top = \`\${hotspot.layout.posY}%\`;
                sp.style.left = \`\${hotspot.layout.posX}%\`;
                sp.style.position = 'absolute';
                sp.style.zIndex = '999';
                sp.innerHTML = buildhotspot(hotspot);
                sp.addEventListener('click', open.bind(this, hotspot));
                app.append(sp);
            });
            const hotspotsElements = document.getElementsByClassName("svg__hotspot--pervasivemind");
            interval = setInterval(doAnimation, 200, hotspotsElements);

        } catch (error) {
            console.log('Error hotspots', error);
        }
    }, 5000)
})()
</script>
`;
