import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'modelos',
                title: 'Modelos',
                type: 'collapsable',
                icon: 'email',
                children: [
                    {
                        id: 'create-model',
                        title: 'Agregar modelo',
                        type: 'item',
                        url: '/model/create'
                    },
                    {
                        id: 'list-models',
                        title: 'Listar Modelos',
                        type: 'item',
                        url: '/model/list'
                    }]
            },
            {
                id: 'cap',
                title: 'CAP',
                type: 'collapsable',
                children: [
                    {
                        id: 'add-sku',
                        title: 'Agregar SKU',
                        type: 'item',
                        url: '/cap/create'
                    },
                    {
                        id: 'list-models',
                        title: 'Listar SKUs',
                        type: 'item',
                        url: '/cap/list'
                    }]
            }


        ]
    },
    {
        id: 'admin',
        title: 'Administración',
        type: 'group',
        icon: 'settings',
        children: [
            {
                id: 'user',
                title: 'Usuarios',
                type: 'collapsable',
                icon: 'group',
                children: [
                    {
                        id: 'list-user',
                        title: 'Listar Usuarios',
                        type: 'item',
                        url: '/user/list'
                    },
                    {
                        id: 'create-user',
                        title: 'Agregar Usuario',
                        type: 'item',
                        url: '/user/create'
                    }
                ]
            },
            {
                id: 'cateogories',
                title: 'Categorias',
                type: 'collapsable',
                icon: 'group',
                children: [
                    {
                        id: 'list-category',
                        title: 'Listar Categorías',
                        type: 'item',
                        url: '/category/list'
                    },
                    {
                        id: 'create-category',
                        title: 'Agregar Categoría',
                        type: 'item',
                        url: '/category/create'
                    }
                ]
            },
            {
                id: 'project-manager',
                title: 'Gestion de proyectos',
                type: 'collapsable',
                icon: 'group',
                children: [
                    {
                        id: 'project-indexado',
                        title: 'Indexado',
                        type: 'collapsable',
                        icon: 'group',
                        children: [
                            {
                                id: 'project-indexado-list',
                                title: 'Listar versiones indexado',
                                type: 'item',
                                url: '/project/indexado/list'
                            },
                            {
                                id: 'project-indexado-add',
                                title: 'Agregar versiones indexado',
                                type: 'item',
                                url: '/project/indexado/add'                                
                            }
                        ]
                    },
                    {
                        id: 'project-omnitok',
                        title: 'Omnitok',
                        type: 'item',
                        url: ''
                    },
                    {
                        id: 'project-hotspots',
                        title: 'Hotspots',
                        type: 'collapsable',
                        icon: 'group',
                        children: [
                            {
                                id: 'project-hotspot-list',
                                title: 'Listar Versiones',
                                type: 'item',
                                url: '/project/hotspots/list'
                            },
                            {
                                id: 'project-hotspot-campaign',
                                title: 'Imágenes Campañas',
                                type: 'item',
                                url: '/project/hotspots/campaign'
                            }
                        ]
                    }
                ]
            }
        ]
    }
];
