import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
/**
 * Servicio que obtiene y gestiona las categorías de producto
 */
export class ClientService {

    /**
     * Constructor
     *
     * @param {HttpClient} httpClient
     */
    clients: any;

    constructor() {
        this.clients = [
            {clientId: 'HP', name: 'HP', 'image': 'assets/images/logos/logo.png'},
            {
                clientId: 'OSTER',
                name: 'Oster',
                'image': 'assets/images/logos/Oster.jpg'
            },
            {
                clientId: 'MICROSOFT',
                name: 'Microsoft',
                'image': 'assets/images/logos/Microsoft.jpg'
            },
            {
                clientId: 'Midea',
                name: 'Midea',
                'image': 'assets/images/logos/Midea.jpg'
            },
            {
                clientId: 'Reckitt',
                name: 'Reckitt',
                'image': 'assets/images/logos/reckit.png'
            },
            {
                clientId: 'Bosca',
                name: 'Bosca',
                'image': 'assets/images/logos/bosca.png'
            },
            {
                clientId: 'Loreal',
                name: 'Loreal',
                'image': 'assets/images/logos/LOREAL.png'
            },
            {
                clientId: 'Rheem',
                name: 'Rheem',
                image: 'assets/images/logos/rheem.png'
            },
            {
                clientId: 'VCT',
                name: 'VCT',
                image: 'assets/images/logos/vct.png'
            },
            {
                clientId: 'Rosen',
                name: 'Rosen',
                image: 'assets/images/logos/rosen.png'
            }
        ];
    }

    getCLientById(idToSearch) {
        return _.find(this.clients, (client) => {
            return idToSearch === client.clientId;
        })
    }

    getClients(): any {
        return this.clients;
    }

    setClient(client): any {
        localStorage.setItem('client', JSON.stringify(client));
        console.log('current client : ', client)
        return client;
    }


    getCurrentClient() {
        if (!localStorage.getItem('client')) {
            let defaultClient = this.getCLientById('HP');
            localStorage.setItem('client', JSON.stringify(defaultClient));
            return defaultClient;
        }else{
            let client = JSON.parse(localStorage.getItem('client'));
            return client
        }
    }


}
