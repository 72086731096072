import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-gallery',
  templateUrl: './content-gallery.component.html',
  styleUrls: ['./content-gallery.component.scss']
})
export class ContentGalleryComponent implements OnInit {

  @Input() images: string[];

  currentImage = 0
  selected:number = 0

  constructor() { }

  ngOnInit(): void {
  }

  displayImage(index): void {
    this.currentImage = index
  }

}
