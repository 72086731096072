import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import {Subject} from 'rxjs';
import {CategoryService} from '../../../services/category.service';

import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

import {ActivatedRoute, Router} from '@angular/router';
import {ModelService} from '../../../services/model.service';
import {ClientService} from '../../../services/client.service';
import {CapService} from '../../../services/cap.service';
import {BroadcastService} from '../../../services/bradcast.services';

@Component({
    selector: 'app-create-model',
    templateUrl: './create.cap.component.html',
    styleUrls: ['./create.cap.component.scss']
})
export class CreateCapComponent implements OnInit {
    model = {};
    capForm;

    errorMessage: string;
    categories: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    clientId;
    id;
    modelData;

    constructor(
        private _formBuilder: FormBuilder,
        private categoryService: CategoryService,
        private modelService: ModelService,
        private fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private _clientService: ClientService,
        private _capService: CapService,
        private _broadCastService: BroadcastService
    ) {
        this.clientId = this._clientService.getCurrentClient().clientId;
    }

    ngOnInit(): void {
        this.model = {};
        this.capForm = this._formBuilder.group({
            sku: ['', Validators.required],
        });
    }

    onSubmit(): void {
        console.log(this.capForm.getRawValue());
        this.fuseProgressBarService.show();

        if (this.capForm.invalid) {
            console.log('IS INVALID');
            return;
        }
        let modelData = this.capForm.getRawValue();

        this._capService.addCap(modelData).subscribe((data) => {
            this.fuseProgressBarService.hide();
            this.showSnackBar('SKU  Creado');
            this.router.navigateByUrl('cap/list');
        });
    }

    showSnackBar(message: string): void {
        this._snackBar.open(message, 'Ok', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
    }

}
