import {NgModule, LOCALE_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';
import { YouTubePlayerModule } from "@angular/youtube-player";


import {fuseConfig} from 'app/fuse-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {IndexadoModule} from 'app/main/indexado/indexado.module';
import { UserModule } from 'app/main/user/user.module';
import {CategoryModule} from './main/category/category.module';
import { registerLocaleData } from '@angular/common';
import localeExtra from 'app/locales/es-CL';
import localeEs from '@angular/common/locales/es-CL';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TokenInterceptor } from 'app/main/user/token.interceptor';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MultimediaDialogComponent } from './multimedia/dialog/multimedia-dialog.component';
import {MatTabsModule} from '@angular/material/tabs';
import {IndexadoComponent} from './main/indexado/indexado.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ModelModule} from './main/model/model.module';
import {MatSliderModule} from '@angular/material/slider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {BroadcastService} from './services/bradcast.services';
import {CapModule} from './main/cap/cap.module';
import { ProjectModule } from './main/project/project.module'

registerLocaleData(localeEs, 'es-CL', localeExtra);
// @ts-ignore
const appRoutes: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'project',
        loadChildren: () => import('./main/project/project.module').then(m => m.ProjectModule)
    },
    {
        path: '**',
        redirectTo: 'indexado'
    }

];

@NgModule({
    declarations: [
        AppComponent,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'es-CL' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        BroadcastService
    ],
    imports: [
        BrowserModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),
        TranslateModule.forRoot(),
        YouTubePlayerModule,
        MatSliderModule,


        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        IndexadoModule,
        CapModule,
        UserModule,
        CategoryModule,
        ModelModule,
        MatTabsModule,
        MatTabsModule,
        MatDialogModule,
        ProjectModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
