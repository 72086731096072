import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ICampaign, ICampaignResponse, IVersion } from '../main/project/project.model';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    private http: HttpClient
  ) { }


  index(module?: string): Observable<{data: ICampaign[]}> {
    const endpointUrl = module ? `${environment.featureUrl}/${module}/campaigns/index` : `${environment.featureUrl}/campaigns/index`;
    return this.http.get<{data: ICampaign[]}>(endpointUrl)
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  get(campaignId: string, module?: string): Observable<{data: ICampaign}> {
    const endpointUrl = module ? `${environment.featureUrl}/${module}/campaigns/get/${campaignId}` : `${environment.featureUrl}/campaigns/get/${campaignId}`
    return this.http.get<{data: ICampaign}>(endpointUrl)
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  save(campaign: ICampaign, module?: string): Observable<ICampaignResponse> {

    const data: ICampaign = {
      ...campaign,
      campaignId: campaign.campaignId ? campaign.campaignId : 'cid-' + Date.now().toString(),
      startDate: this.getFormatDate(campaign.startDate),
      endDate: this.getFormatDate(campaign.endDate),
    } 
    const endpointUrl = module ? `${environment.featureUrl}/${module}/campaigns/save` : `${environment.featureUrl}/campaigns/save`;
    if (module === 'hotspots') {
      delete data.urlNotShow;
      delete data.urls;
      delete data.version;
    }
    return this.http.post<ICampaignResponse>(endpointUrl, {
      data
    })
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  delete(campaignId: string, module?: string): Observable<{status: number}> {
    const endpointUrl = module ? `${environment.featureUrl}/${module}/campaigns/delete/${campaignId}` : `${environment.featureUrl}/campaigns/delete/${campaignId}`;
    return this.http.delete<{status: number}>(endpointUrl,)
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getVersionsCustomNames(lang: string, version: string): Observable<{data: string[]}> {
    return this.http.get<{data: string[]}>(`${environment.featureUrl}/customVersion/get/${lang}/${version}`, {

    })
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  private getFormatDate(s: string): string {
    const date = new Date(s);
    const y = date.getFullYear();
    const m = date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    const d = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

    return `${y}-${m}-${d}`
  }
}
