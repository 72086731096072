import { Pipe, PipeTransform } from '@angular/core';

const _ = require("lodash")

interface IHasValue {
  hasValue: boolean,
  value: any
}

@Pipe({
  name: 'dropdownInvalidValToString'
})
export class DropdownInvalidValToStringPipe implements PipeTransform {
  transform(obj: any = {}, path: string = '', str = 'seleccionar'): any {

    if (!this.keyExistsInObj(obj, path)) {
      return str
    }

    let keyHasValue: IHasValue = this.keyHasValue(obj, path)

    if (keyHasValue.hasValue) {
      return keyHasValue.value
    }
    
    return str
  }

  keyExistsInObj(obj: any, path: string): boolean {
    const keyExists = _.has(obj, `${path}`)

    return keyExists
  }

  keyHasValue(obj: any, path: string): IHasValue {
    const value = _.get(obj, path)
    const hasValue = typeof value != 'undefined' && typeof value != 'object' && value != null
    const result: IHasValue = {hasValue, value}
    
    return result
  }
}
