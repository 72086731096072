export interface IHotSpotAdditionalAttr {
  key: string;
  value: any;
}

export enum HOTSPOT_TYPES {
  GALLERY = 'GALLERY',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  KSP = 'KSP',
  EMPTY = 'EMPTY'
}

export enum ETypeModalDialog {
  EDIT_KSP_MODAL = "EDIT_KSP_MODAL",
  EDIT_TEXT_MODAL = "EDIT_TEXT_MODAL",
  EDIT_GALLERY_MODAL = "EDIT_GALLERY_MODAL",
  BUTTONCONFIRM = "BUTTONCONFIRM",
  INFOMODAL = "INFOMODAL",
  ERRORMODAL = "ERRORMODAL",
  BULKLOAD_HOTSPOTS_MODAL = "BULKLOAD_HOTSPOTS_MODAL",
  ADD_NEW_ELEMENT = 'ADD_NEW_ELEMENT',
  SELECT_HOTSPOTS_FORMAT = 'SELECT_HOTSPOTS_FORMAT',
  ADD_KSP_MODAL = 'ADD_KSP_MODAL',
  RETAIL_CONFIG_HOTSPOTS_MODAL = 'RETAIL_CONFIG_HOTSPOTS_MODAL'
}
export interface IHotSpot {
  id: string,
  additionalAttr: IHotSpotAdditionalAttr[];
  text: string;
  backgroundIcon: string;
  colorIcon: string;
  backgroundImage: string;
  type: HOTSPOT_TYPES;
  layout: {
      posX: number,
      posY: number,
      width: number,
      height: number
  };
  visible: boolean;
}
export interface IHotSpotVersion {
  id: string;
  language: string;
  visible: boolean;
  template: string;
  version: string;
  backgroundImage: string;
  hotspots: IHotSpot[];
  container: string;
  containerType: string;
  retail: string;
  isDefault: boolean;
}

export interface IModalDialogComponent {
  modal: ITypeModalDialog
  selectModalType():void 
}

export interface ITypeModalDialog {
  KSP_MODAL:string;
  BUTTONCONFIRM:string;
  ICONVERSION:string;
  ERRORMODAL: string;
}
export interface IModalDialogDataKsp {
  title: string;
  img?: any;
  description?: string;
  icono?: string;
  ksp4?: string;
  sku: string;
  index?: number
  ksps?: {icon: string, image: string, text: string, title: string}[]
  language?:string
}
export interface IModalDialogDataText {
  title: string;
  description?: string;
  icono?: string;
  sku: string;
  index?: number
  texts: any[]
  language?: string
}

export interface IModalDialogDataGallery {
  images: string[]
}

export enum IndexadoRows {
  imagesKspCards = '3.1',
  imagesKspCardsContents = '3.1.2',
  dataTextsCards = '4.2',
  dataTextsCardsContents = '4.2.2',
}

