import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-standard-btn',
  templateUrl: './standard-btn.component.html',
  styleUrls: ['./standard-btn.component.scss']
})
export class StandardBtnComponent implements OnInit, OnChanges {

  @Input() name: string
  @Input('size') inputSize: string
  @Input('color') inputColor: string

  private size: string = 'xs'
  private color: string
  private baseClass: string = 'standardBtn'

  public className: string

  constructor() {
  }

  ngOnInit(): void {
    this.size = this.inputSize || 'xs'
    this.color = this.inputColor
    this.className += `${this.baseClass} ${this.inputSize} ${this.inputColor}`
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputSize) {
      this.size = changes.inputSize.currentValue
    }

    if (changes.inputColor) {
      this.color = changes.inputColor.currentValue
    }
    
    this.className = `${this.baseClass} ${this.size} ${this.color}`
  }

}
