import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {environment as env} from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
/**
 * Servicio que obtiene y gestiona los usuarios locales de artefacto inflow-api
 */
export class ImagesService {

    /**
     * Constructor
     *
     * @param {HttpClient} httpClient
     */
    filter : any;
    where : any;
    constructor(
        private httpClient: HttpClient
    ) {
    }

    getImages(name = null, page = 0, limit = 100, pageSize = 10, isCount = false): Observable<any> {
        let params = new HttpParams();
        let endpoint:string;
        if (isCount){
            this.where= {
                    createdAt: { gte: new Date('"2023-12-01"').toISOString()},

            }
            params = params.append('where', JSON.stringify(this.where));
            endpoint = `${env.apiUrl}/images/count`;

        }else{
            this.filter = {
                limit: page * limit <=0? limit: limit *(page+1),
                order : 'createdAt DESC',
                where : {
                    contentType:{
                        neq: 'video/mp4'
                    }
                }
            };
            if (name !== null) {
                // console.log("name search: ",name);
                this.filter.where = {
                    and:[
                        {
                            filename: {
                                like: '.*' + name + '.*', options: 'igm'
                            }
                        },
                        {
                            contentType:{
                                neq: 'video/mp4'
                            }
                        }
                    ]
                };
            }
            this.filter.offset = page * pageSize;
            params = params.append('filter', JSON.stringify(this.filter));
            endpoint = `${env.apiUrl}/images`;
        }
        return this.httpClient.get<any[]>(`${endpoint}`, { params: params });
    }


}
