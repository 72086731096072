import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'

@Component({
  template: `
  <h1 mat-dialog-title>Confirmación Requerida</h1>
  <mat-dialog-content *ngIf="data">{{data}}</mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close] cdkFocusInitial>cancelar</button>
    <button mat-button (click)="onConfirm()">confirmar</button>
  </div>`
})

export class ModalDialogConfirmation {

  constructor(
    public dialogRef: MatDialogRef<ModalDialogConfirmation>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('Data en el modal---', data);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}