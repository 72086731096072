import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import { ProfileType, UserService} from '../../../services/user.service';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import {Router} from '@angular/router';
import { ModalService } from 'app/services/modal.service';
import { ERROR_LOAD_PROFILES } from 'app/Messages';

@Component({
  selector: 'create.user',
  templateUrl: './create.user.component.html',
  styleUrls: ['./create.user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {

  public title: string;
  public profiles: ProfileType[];
  form: FormGroup;

  // Private
  private _unsubscribeAll: Subject<any>;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   * @param userService
   * @param fuseProgressBarService
   * @param _snackBar
   * @param router
   */
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private fuseProgressBarService: FuseProgressBarService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private modalDialogService: ModalService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.title = 'Create User Form';
    this.fuseProgressBarService.setMode('indeterminate');

    this.userService.getProfiles().subscribe((profiles) => {
      this.profiles = profiles;
    }, (err) => {
      this.modalDialogService.selectModalType('ERRORMODAL', {
          message: ERROR_LOAD_PROFILES,
          error: err
      })
    });



    // Reactive Form
    this.form = this._formBuilder.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', Validators.required],
      profileId: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onSubmit(): void {
    this.fuseProgressBarService.show();

    this.userService.addUser(this.form.value).subscribe((data) => {
      this.fuseProgressBarService.hide();
      this.showSnackBar('Usuario Creado');
      this.router.navigateByUrl('user/list');
    });
  }

  showSnackBar(message: string): void {
    this._snackBar.open(message, 'Ok', {
      duration: 4000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }


}
