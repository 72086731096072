import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'

export interface IErrorInfo {
  message: string
  error: any
}

@Component({
  template: `
  <h1 mat-dialog-title>Atención</h1>
  <div *ngIf="data">
    <mat-dialog-content>{{data.message}}</mat-dialog-content>
  </div>
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="close()">Aceptar</button>
  </div>`
})

export class ModalDialogError {

  constructor(
    public dialogRef: MatDialogRef<ModalDialogError>,
    @Inject(MAT_DIALOG_DATA) public data: IErrorInfo,
  ) {
  }

  close(): void {
    this.dialogRef.close()
  }
  // TODO: añadir métodos para tomar acciones. Por ejemplo:
  // - reintentar
  // - reportar log del error
}