import { Component, OnInit, Input } from '@angular/core';
import {IHotSpot} from '../hotspots.model';

@Component({
  selector: 'app-button-empty',
  templateUrl: './button-empty.component.html',
  styleUrls: ['./button-empty.component.scss']
})
export class ButtonEmptyComponent implements OnInit {

  @Input() config: IHotSpot;
  @Input() selected: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
