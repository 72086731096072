import {Component, Input, OnInit} from '@angular/core';
import {MultimediaDialogComponent} from '../dialog/multimedia-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment'
@Component({
    selector: 'app-input-multimedia',
    templateUrl: './input.multimedia.component.html',
    styleUrls: ['./input.multimedia.component.scss']
})
export class InputMultimediaComponent implements OnInit {
    env = environment;
    constructor(
        public modal: MatDialog
    ) {

    }
    @Input() label: string;
    @Input() showImageLoaded: boolean;
    @Input() element: any;
    @Input() property: string;
    @Input() multi: boolean;
    @Input() type: string;
    elementOnString;
    fileUploadUrl: string;
    ngOnInit(): void {
        this.elementOnString = JSON.stringify(this.element)
    }
    openModal(element = null, field = 'value', multi = false) {
        if(!this.type){
            this.type = 'images';
        }
        switch (this.type) {
            case 'CAP':
                this.fileUploadUrl = this.env.apiCapUploadUrl;
                break;
            case 'images':
                this.fileUploadUrl = this.env.apiFilesUrl;
                break;
            default:
                this.fileUploadUrl = this.env.apiFilesUrl;
                break;
        }

        const dialogRef = this.modal.open(MultimediaDialogComponent, {
            data: {
                element: element || null,
                multi: multi,
                fileUploadUrl : this.fileUploadUrl,
                type: this.type
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if(element){
                if (Array.isArray(result)) {
                    element[field] = result;
                } else {
                    element[field] = result.storageId;
                }
            }

        });
    }
    waitAndReload(event) {
        const originalSrc = event.target.src;
        if (parseInt(event.target.getAttribute('data-retry'), 10) !== parseInt(event.target.getAttribute('data-max-retry'), 10)) {
            event.target.setAttribute('data-retry', parseInt(event.target.getAttribute('data-retry'), 10) + 1);
            setTimeout(function () {
                event.target.src = originalSrc;
            }, 1000);
        }
    }
    delete(){
        this.element[this.property]=null
    }
}
