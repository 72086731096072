import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content-ksp',
  templateUrl: './content-ksp.component.html',
  styleUrls: ['./content-ksp.component.scss']
})
export class ContentKspComponent implements OnInit {

  @Input() ksps: [];
  @Input() text: string;
  @Input() title: string;

  currentImage = 0
  selected:number = 0
  constructor() { }

  ngOnInit(): void {
    console.log(this.title)
    console.log(this.text)

  }

  displayImage(index): void {
    this.currentImage = index
  }

}
