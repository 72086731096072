import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { confirm_delete_hs_version } from 'app/Messages';
import { ModalService } from 'app/services/modal.service';

@Component({
  selector: 'app-header-versions',
  templateUrl: './header-versions.component.html',
  styleUrls: ['./header-versions.component.scss']
})
export class HeaderVersionsComponent implements OnInit {

  @Input() id = '';
  @Input() title = '';
  @Input() index:number;
  @Input() selected = false;
  @Input() isPreview: boolean;
  
  @Output() handle = new EventEmitter<string>();
  @Output() handleRemove = new EventEmitter<string>();

  constructor(private modalDialogService:ModalService) { }

  ngOnInit(): void {
  }

  select() {
    this.handle.emit(this.id);
  }

}
