import { AfterViewInit, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotspotService } from 'app/services/hotspots/hotspot.service';
import { ModalService } from 'app/services/modal.service';
import { ERROR_GET_METADATA_LIST, ERROR_SAVE_INFO, INFO_SAVED_SUCCESSFULLY } from 'app/Messages';
import { IRetail } from 'app/main/project/hotspots/hotspots.model';
import { FormControl } from '@angular/forms';



/**
 * @title Table with pagination
 */
@Component({
  selector: 'app-hotspots-retail-config',
  styleUrls: ['modal-retail-config-hotspots.component.scss'],
  templateUrl: 'modal-retail-config-hotspots.component.html',
})
export class ModalRetailConfigHotspotsComponent implements AfterViewInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredSkus: Observable<string[]>;
  keys: string[] = [];
  urls: string[] = []
  allValues: string[] = [];
  private version: string;
  private baseSku: string;
  private language: string;
  private isLoading: boolean;
  private hotspotsConfig: any[] = [];
  public processHaveValues:boolean = false
  public ELEMENT_DATA: ValueElement[] = [];
  public ELEMENTURL_DATA: ValueElement[] = [];
  public ELEMENT_DATA_HOTSPOTS: ValueElementH[] = [];
  private publishAll:boolean;
  private publishAllUrl:boolean;
  private isLayout:boolean = false;
  retail:string;
  targetContainer: string;
  targetContainerType: string;
  isTarget:boolean = false;
  retailData: IRetail
  selected = new FormControl(0);

  constructor(
    public dialogRef: MatDialogRef<ModalRetailConfigHotspotsComponent>,
    private modalDialogService: ModalService,
    private hotSpotsService: HotspotService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.version = data.version
    this.baseSku = data.baseSku
    this.language = data.language
    this.hotspotsConfig = data.hotspotsData
    this.isLoading = false
    this.publishAll = false
    this.publishAllUrl = false
  }

  displayedColumns: string[] = ['val', 'publish'];
  displayedColumnsH: string[] = ['type','posX','posY'];
  dataSource = new MatTableDataSource<ValueElement>(this.ELEMENT_DATA);
  dataSourceUrls = new MatTableDataSource<ValueElement>(this.ELEMENTURL_DATA);
  dataSourceH = new MatTableDataSource<ValueElementH>(this.ELEMENT_DATA_HOTSPOTS);

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  ngOnInit(){
    const hotspotsData= this.hotspotsConfig.map(hotSpot => ({
      type: hotSpot.type,
      colorIcon: hotSpot.colorIcon,
      backgroundIcon: hotSpot.backgroundIcon,
      layout: hotSpot.layout
    }));
    hotspotsData.forEach(element => {
      this.ELEMENT_DATA_HOTSPOTS.push(element)
    });
    // console.log("ELEMENT_DATA_H:::::::",this.ELEMENT_DATA_HOTSPOTS)
    // this.dataSourceH._updateChangeSubscription()
    this.dataSourceH.data = [...this.dataSourceH.data];
    // console.log("dataSourceH", this.dataSourceH.data)
    
    // const payload:any ={
    //   host: this.data.retail,
    //   feature: 'HOTSPOTS'
    // } 
    // this.hotSpotsService.getFeatureMetadata(payload)
    // .subscribe((response:{status:number, data:any}) => {
    //   if (response.status === 200 && response.data) {
    //     let urlData = response.data.find((m:any) => m.key ==='versions')
    //     if (urlData){
    //       let arrUrls= urlData.value.filter((url:any)=> url.version === this.data.version).map((u:any)=> { 
    //         return {
    //           status: true,
    //           val: u.fullUrl,
    //           publish: true
    //         }
    //         })
    //         arrUrls.forEach(element => {
    //           this.ELEMENTURL_DATA.push(element)
    //         });
    //     }
    //     let keywordData = response.data.find((m:any) => m.key ==='keywords')
    //     if(keywordData){
    //       let arrKeywords = keywordData.value.filter((keyword:any)=> keyword.version === this.data.version).map((k:any)=> {
    //         return {
    //           status: true,
    //           val : k.keyword,
    //           publish: true
    //         }
    //       })

    //       arrKeywords.forEach(element => {
    //         this.ELEMENT_DATA.push(element)
    //       });
    //     }
    //     this.dataSource._updateChangeSubscription()
    //     this.dataSourceUrls._updateChangeSubscription()
    //   }else{
    //     this.modalDialogService.selectModalType('ERRORMODAL', {
    //       message: ERROR_GET_METADATA_LIST,
    //       error: 'Error en servidor al obtener información'
    //     });
    //   }
    //   this.isLoading = false;
    // },
    // (err: any) => {
    //   this.isLoading = false;
    //   this.modalDialogService.selectModalType('ERRORMODAL', {
    //     message: ERROR_GET_METADATA_LIST,
    //     error: err
    //   })
    // });

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.dataSourceUrls.paginator = this.paginator.toArray()[1];
    this.dataSourceH.paginator = this.paginator.toArray()[2];
  }

  add(event: MatChipInputEvent): void {
    let values= event.value.split(',')
    if (Array.isArray(values)){
      if(values.length > 0){
        values.forEach(element => {
          if ((element || '').trim()){
            if(!this.ELEMENT_DATA.find(k => k.val === element)){
              this.keys.push((element || '').trim())
              this.processHaveValues = false
            }
          }
        });
      }

    }else{
      const value = (event.value || '').trim();
      if (value) {
        if(!this.ELEMENT_DATA.find(k => k.val === value)){
          this.keys.push(value);
          this.processHaveValues = false
        }
      }
    }

    if (event.input) {
      event.input.value = '';
    }

    this.keys.forEach(element => {
      const findValues = this.ELEMENT_DATA.find(sku => sku.val === element)
      if (!findValues) {
        this.ELEMENT_DATA.push({status:undefined,val:element,publish:false})
      } 
    });
    this.validateAll()
    this.dataSource._updateChangeSubscription()
  }

  addUrl(event: MatChipInputEvent): void {
    let values= event.value.split(',')
    if (Array.isArray(values)){
      if(values.length > 0){
        values.forEach(element => {
          if ((element || '').trim()){
            if(!this.ELEMENTURL_DATA.find(url => url.val === element)){
              this.urls.push((element || '').trim())
              this.processHaveValues = false
            }
          }
        });
      }

    }else{
      const value = (event.value || '').trim();
      if (value) {
        if(!this.ELEMENTURL_DATA.find(url => url.val === value)){
          this.urls.push(value);
          this.processHaveValues = false
        }
      }
    }

    if (event.input) {
      event.input.value = '';
    }

    this.urls.forEach(element => {
      const findValues = this.ELEMENTURL_DATA.find(url => url.val === element)
      if (!findValues) {
        this.ELEMENTURL_DATA.push({status:undefined,val:element,publish:false})
      } 
    });
    this.validateAll()
    this.dataSourceUrls._updateChangeSubscription()
  }

  async getCheckbox() {
    if ( this.ELEMENT_DATA.filter((i) => i.publish === true).length> 0){
      this.processHaveValues = true
    }else{
      this.processHaveValues = false
    }
    this.validateAll()
    this.dataSource._updateChangeSubscription()

  }
  async getCheckboxUrl() {
    if ( this.ELEMENTURL_DATA.filter((i) => i.publish === true).length> 0){
      this.processHaveValues = true
    }else{
      this.processHaveValues = false
    }
    this.validateAll()
    this.dataSourceUrls._updateChangeSubscription()

  }

  validateAll(){
    const elementsToPublish = this.ELEMENT_DATA.filter((i) => i.publish === true).length
    const elementsUrlToPublish = this.ELEMENTURL_DATA.filter((i) => i.publish === true).length
    const totalElementsUrl =  this.ELEMENTURL_DATA.length
    const totalElementsKey = this.ELEMENT_DATA.length
    const totalElements = this.ELEMENT_DATA.length + this.ELEMENTURL_DATA.length
    if (!this.publishAll){
      this.publishAll = elementsToPublish  === totalElementsKey && elementsToPublish > 0
    }
    if (!this.publishAllUrl){
      this.publishAllUrl = elementsUrlToPublish  === totalElementsUrl && elementsUrlToPublish > 0
    }
  }

  async changeChkState(id, prop) {
    this.ELEMENT_DATA.forEach((chk) => {
      if (chk.val === id || id==='all') {
        switch (prop) {
          case 'publishAll':
              chk.publish = this.publishAll
            break;
          default:
            break;
        }
        this.getCheckbox()
      }
    });
  }

  async changeChkStateUrl(id, prop){
    this.ELEMENTURL_DATA.forEach((chk) => {
      if (chk.val === id || id==='all') {
        switch (prop) {
          case 'publishAll':
            chk.publish = this.publishAllUrl
            break;
          default:
            break;
        }
        this.getCheckboxUrl()
      }
    });
  }
  remove(fruit: string): void {
    const index = this.keys.indexOf(fruit);
    const indexE = this.ELEMENT_DATA.map(function(e) { return e.val; }).indexOf(fruit);
    if (index >= 0) {
      this.keys.splice(index, 1);
      if (indexE >= 0) {
        this.ELEMENT_DATA.splice(indexE, 1);
        const val = this.ELEMENT_DATA.filter((i) => i.publish === true);
        if (val.length > 0){
          this.processHaveValues= true
        }else{
          this.processHaveValues = false
        }
        this.validateAll()
        this.dataSource._updateChangeSubscription()
      }
    }
  }

  removeUrl(fruit: string): void {
    const index = this.urls.indexOf(fruit);
    const indexE = this.ELEMENTURL_DATA.map(function(e) { return e.val; }).indexOf(fruit);
    if (index >= 0) {
      this.urls.splice(index, 1);
      if (indexE >= 0) {
        this.ELEMENTURL_DATA.splice(indexE, 1);
        const val = this.ELEMENTURL_DATA.filter((i) => i.publish === true);
        if (val.length > 0){
          this.processHaveValues= true
        }else{
          this.processHaveValues = false
        }
        this.validateAll()
        this.dataSourceUrls._updateChangeSubscription()
      }
    }
  }

  onConfirm(){
  this.isLoading = true;
  const fullMetadata:any[] = []
  if (this.ELEMENTURL_DATA.filter((i) => i.publish === true).length > 0){
    const urlData = {
      key:'versions',
      value: this.ELEMENTURL_DATA.filter((i) => i.publish === true).map(item => {return {fullUrl:item.val, version:this.data.version}}),
    }
    fullMetadata.push(urlData)
  }
  if (this.ELEMENT_DATA.filter((i) => i.publish === true).length > 0){
    const keywordData = {
      key:'keywords',
      value: this.ELEMENT_DATA.filter((i) => i.publish === true).map(item => {return {keyword:item.val, version:this.data.version}}),
    }
    fullMetadata.push(keywordData)
  }

  const payload:any ={
    host: this.data.retail,
    metadata: fullMetadata,
    version: this.data.version
  } 
  this.hotSpotsService.saveHotspotsMetadata(payload)
  .subscribe((response:{status:number, data:any}) => {
    if (response.status === 200 && response.data) {
      this.modalDialogService.selectModalType('INFOMODAL', INFO_SAVED_SUCCESSFULLY);
      this.dialogRef.close()
    }else{
      this.modalDialogService.selectModalType('ERRORMODAL', {
        message: ERROR_SAVE_INFO,
        error: 'Error en servidor al guardar información'
      });
    }
    this.isLoading = false;
  },
  (err: any) => {
    this.isLoading = false;
    this.modalDialogService.selectModalType('ERRORMODAL', {
      message: ERROR_SAVE_INFO,
      error: err
    })
  });
  }

  saveHotspotsLayout(){
    this.isLoading = true;
    const fullMetadata:any[] = []
    const layoutConfig = {
      key:'layoutConfig',
      value: [{version:this.data.version,layout:this.dataSourceH.data}],
    }
    fullMetadata.push(layoutConfig)
    const payload:any ={
      host: this.data.retail,
      metadata: fullMetadata,
      version: this.data.version
    }
    this.hotSpotsService.saveHotspotsMetadata(payload)
    .subscribe((response:{status:number, data:any}) => {
      if (response.status === 200 && response.data) {
        this.modalDialogService.selectModalType('INFOMODAL', INFO_SAVED_SUCCESSFULLY);
        this.dialogRef.close()
      }else{
        this.modalDialogService.selectModalType('ERRORMODAL', {
          message: ERROR_SAVE_INFO,
          error: 'Error en servidor al guardar información'
        });
      }
      this.isLoading = false;
    },
    (err: any) => {
      this.isLoading = false;
      this.modalDialogService.selectModalType('ERRORMODAL', {
        message: ERROR_SAVE_INFO,
        error: err
      })
    });
  }

  changeRetail(e: {value: IRetail}) {
    this.retailData = e.value;
    this.data.retail = e.value.url;
    const payload:any ={
      host: e.value.url,
      feature: 'HOTSPOTS'
    } 
    this.hotSpotsService.getFeatureMetadata(payload)
    .subscribe((response:{status:number, data:any}) => {
      if (response.status === 200 && response.data) {
        let urlData = response.data.find((m:any) => m.key ==='versions')
        this.ELEMENTURL_DATA.splice(0, this.ELEMENTURL_DATA.length);
        if (urlData){
          let arrUrls= urlData.value.filter((url:any)=> url.version === this.data.version).map((u:any)=> { 
            return {
              status: true,
              val: u.fullUrl,
              publish: true
            }
            })
            arrUrls.forEach(element => {
              this.ELEMENTURL_DATA.push(element)
            });
        }
        let keywordData = response.data.find((m:any) => m.key ==='keywords')
        this.ELEMENT_DATA.splice(0, this.ELEMENT_DATA.length);
        if(keywordData){
          let arrKeywords = keywordData.value.filter((keyword:any)=> keyword.version === this.data.version).map((k:any)=> {
            return {
              status: true,
              val : k.keyword,
              publish: true
            }
          })

          arrKeywords.forEach(element => {
            this.ELEMENT_DATA.push(element)
          });
        }
        this.dataSource._updateChangeSubscription()
        this.dataSourceUrls._updateChangeSubscription()
        // this.dataSourceH._updateChangeSubscription()
      }else{
        this.modalDialogService.selectModalType('ERRORMODAL', {
          message: ERROR_GET_METADATA_LIST,
          error: 'Error en servidor al obtener información'
        });
      }
      this.isLoading = false;
    },
    (err: any) => {
      this.isLoading = false;
      this.modalDialogService.selectModalType('ERRORMODAL', {
        message: ERROR_GET_METADATA_LIST,
        error: err
      })
    });
  }
}
export interface ValueElement {
  val: string;
  publish: boolean;
  status: boolean;
}

export interface ValueElementH {
  type: string;
  colorIcon: string;
  backgroundIcon: string;
  layout: {
    posX: number,
    posY: number,
    width: number,
    height: number
  };
}


