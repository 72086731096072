import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import {Subject} from 'rxjs';
import {CategoryService} from '../../../services/category.service';
import * as _ from 'lodash';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

import {ActivatedRoute, Router} from '@angular/router';
import {ModelService} from '../../../services/model.service';
import {ClientService} from '../../../services/client.service';
import {BroadcastService} from '../../../services/bradcast.services';

@Component({
    selector: 'app-create-model',
    templateUrl: './create.model.component.html',
    styleUrls: ['./create.model.component.scss']
})
export class CreateModelComponent implements OnInit {
    model : any = {
    };
    modelForm;

    errorMessage: string;
    categories: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    clientId;
    id;
    modelData;
    selectedCategory;
    constructor(
        private _formBuilder: FormBuilder,
        private categoryService: CategoryService,
        private modelService: ModelService,
        private fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private _clientService: ClientService,
        private _broadCastService: BroadcastService
    ) {
        this.clientId = this._clientService.getCurrentClient().clientId;
    }

    ngOnInit(): void {
        this.getCategories();
        this.model = {
            langs : {
                esCL : {
                    name : 'Ingrese nombre',
                    skuRetail: '',
                    eanString: ''
                }
            }
        };
        console.log('name', this.model.langs.esCL.name)
        this.modelForm = this._formBuilder.group({
            name: ['', Validators.required],
            sku: ['', Validators.required],
            skuRetail: [''],
            categoryId: ['', Validators.required],
            eanString: [''],
            description: ['']
        });


        this._broadCastService.subscribe((val) => {
                if (val.type === 'client' && val.value.clientId) {
                    this.clientId = val.value.clientId;
                    this.categoryService.getCategories(this.clientId).subscribe((categories) => {
                        this.categories = categories;
                        console.log('categoires of client:', this.categories);
                    })

                    console.log('Cambio ClientId : ', this.clientId);
                }
            }
        );
    }

    setCategory(){
        debugger;
        this.selectedCategory = _.find(this.categories, (category) => {
            return category.id === this.model.categoryId;
        })
        debugger;
    }

    getCategories() {
        this.categoryService.getCategories(this.clientId).subscribe((categories) => {
            this.categories = categories
        });
    }

    onSubmit(): void {
        console.log(this.modelForm.getRawValue());
        this.fuseProgressBarService.show();

        // stop here if form is invalid
        if (this.modelForm.invalid) {
            console.log('IS INVALID');
            return;
        }
        let modelData = this.modelForm.getRawValue();
        modelData.langs = {
            esCL : {
                name : modelData.name,
                created : new Date(),
                status : 'Aprobado',
                eanString: modelData.eanString,
                skuRetail: modelData.skuRetail
            }
        }

        modelData.clientId = this.clientId;


        this.modelService.addModel(modelData).subscribe((data) => {
            this.fuseProgressBarService.hide();
            this.showSnackBar('Modelo Creado');
            this.router.navigateByUrl('model/list');
        });
    }

    showSnackBar(message: string): void {
        this._snackBar.open(message, 'Ok', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
    }

}
