import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalDialogDataKsp } from '../../../hotspots.model';
import { ModelService } from 'app/services/model.service';
import { MultimediaDialogComponent } from '../../../../../../multimedia/dialog/multimedia-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-modal-edit-ksp',
  templateUrl: './modal-edit-ksp.component.html',
  styleUrls: ['./modal-edit-ksp.component.scss']
})
export class ModalEditKSPComponent implements OnInit {
  sku: string;
  diferenciators: any[];
  kspSelected: number;
  isLoading: boolean;
  language:string;

  constructor(
    public dialogRef: MatDialogRef<ModalEditKSPComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalDialogDataKsp,
    private modelService: ModelService,
    public multimedia: MatDialog,
  ) {
    this.isLoading = true;
      this.sku = this.data.sku;
      this.language = this.data.language?this.data.language:'esCL';
      this.modelService.getModels(this.sku, 'HP').subscribe((response: any) => {
        this.isLoading = false;
        if (response.length > 0) {
          const model = response[0];
          if (model.langs && model.langs[this.language] && model.langs[this.language].diferenciadores) {
            this.diferenciators = model.langs[this.language].diferenciadores.map((differ: any, index: number) => {
              if (data.ksps && data.ksps.length > index) {
                differ.image = data.ksps[index].image;
              }
              return differ;
            });
          }
        }
        this.kspSelected = -1;
      },
      (err: any) => {
         this.diferenciators = [];
      });
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close({action: 'cancel'});
  }

  onClick() {
    this.dialogRef.close({action: 'save', data: this.diferenciators});
  }

  select(different, i: number) {
    this.kspSelected = i;
    this.data.description = different.descripcion;
    this.data.title = different.titulo;
    this.data.icono = different.imagen[0].url;
  }

  addImage(different: {titulo: string, descripcion: string, image: string}) {
    const dialogRef = this.multimedia.open(MultimediaDialogComponent, {
      data: {
          element: different,
          multi: false,
          fileUploadUrl: environment.apiFilesUrl,
          type: "images"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result.storageId) {
          different.image = 'https://media.alquimio.cloud/images/@thumbs_256_' + result.storageId + '.webp';
        }
    });
  }

  removeImage(different: {titulo: string, descripcion: string, image: string}) {
    different.image = ``;
  }

}
