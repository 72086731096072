import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModelService} from '../../../services/model.service';
import {Model} from '../model.model';
import {MatDialog} from '@angular/material/dialog';
import {fuseAnimations} from '@fuse/animations';
import {FormGroup} from '@angular/forms';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, mergeMap} from 'rxjs/operators';
import {BroadcastService} from '../../../services/bradcast.services';
import {ClientService} from '../../../services/client.service';
import * as _ from "lodash";
import { ModalService } from 'app/services/modal.service';
import { ERROR_GET_CATEGORIES_LIST } from 'app/Messages';

@Component({
    selector: 'list.model',
    templateUrl: './list.model.component.html',
    styleUrls: ['./list.model.component.scss'],
    animations: fuseAnimations
})
export class ListModelComponent implements OnInit {

    displayedColumns: string[] = ['nombre', 'sku', 'buttons'];
    dataSource: MatTableDataSource<Model>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    public title: string;
    loading: boolean;
    public models: any[];
    client;
    clientId;
    /**
     * Constructor
     */
    constructor(
        private modelService: ModelService,
        public _matDialog: MatDialog,
        public router: Router,
        private fuseProgressBarService: FuseProgressBarService,
        private broadcastService: BroadcastService,
        private _clientService: ClientService,
        private modalDialogService: ModalService
    ) {
        this.loading = true;
        this.fuseProgressBarService.show();
        this.clientId = this._clientService.getCurrentClient().clientId;
        this.modelService.getModels(null, this.clientId).subscribe((data) => {
            this.models = data;
            debugger;
            _.forEach(this.models, (model) => {
                if(!model.langs){
                    model.nombre = model.name || model.nombre;
                }
                if(model.langs != null && model.langs.esCL != null){
                    model.nombre =  model.langs.esCL.name != null ? model.langs.esCL.name : model.langs.esCL.nombre;
                    debugger;
                }
                if(model.langs && model.langs.PT){
                    model.nombre =  model.langs.PT.name != null ? model.langs.PT.name : model.langs.PT.nombre;
                }
                return model;
            })

            this.dataSource = new MatTableDataSource(this.models);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loading = false;
        }, (err) => {
            this.modalDialogService.selectModalType('ERRORMODAL', {
                message: ERROR_GET_CATEGORIES_LIST,
                error: err
            })
        });
    }

    subscription;
    searchTextChanged = new Subject<string>();

    ngOnInit(): void {
        this.title = 'Lista de Categorías';
        this.subscription = this.searchTextChanged.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            mergeMap(search => this.modelService.getModels(search, this.clientId))
        ).subscribe((data) => {
            this.models = data;
            _.forEach(this.models, (model) => {

                if(model.langs && model.langs.esCL){
                    model.nombre =  model.langs.esCL.name != null ? model.langs.esCL.name : model.langs.esCL.nombre;
                }
                if(model.langs && model.langs.PT){
                    model.nombre =  model.langs.PT.name != null ? model.langs.PT.name : model.langs.PT.nombre;
                }
                return model;
                console.log('models', model);
            })
            debugger;
            this.dataSource = new MatTableDataSource(this.models);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        });

        this.broadcastService.subscribe((val) => {
                if (val.type === 'client' && val.value.clientId) {
                    this.clientId = val.value.clientId;
                    this.getModels();
                }
            }
        );
    }
    getModels(){
        this.modelService.getModels(null, this.clientId).subscribe((data) => {
            this.models = data;
            this.dataSource = new MatTableDataSource(this.models);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loading = false;
        }, (err) => {
            this.modalDialogService.selectModalType('ERRORMODAL', {
                message: ERROR_GET_CATEGORIES_LIST,
                error: err
            })
        });
    }






    /**
     * Edit model
     *
     * @param model
     */
    editIndexado(model): void {
        this.router.navigateByUrl('/indexado/' + model.id);
    }

    editModel(model): void {
        console.log('edit model', model.id);
        this.router.navigateByUrl('/model/edit/' + model.id);
    }
}
