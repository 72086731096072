import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';


@Component({
  selector: 'edit.user',
  templateUrl: './edit.user.component.html',
  styleUrls: ['./edit.user.component.scss']
})
export class EditUserComponent implements OnInit {
  /**
   * Constructor
   *
   */
  public title: string;

  constructor() {
    console.log('Ex On Init');
  }

  ngOnInit(): void {
    this.title = 'Edit User Form';
    console.log('Ex On Init');
  }


}
