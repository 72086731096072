import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';

import { AddComponent } from './indexado/add/add.component';
import { ListComponent } from './indexado/list/list.component';

import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRippleModule} from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { MatChipsModule} from '@angular/material/chips'
import { MatMenuModule } from '@angular/material/menu';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FuseProgressBarModule} from '@fuse/components';

const routes = [
  {
    path: 'project/indexado/list',
    component: ListComponent
  },
  {
    path: 'project/indexado/add',
    component: AddComponent
  },
  {
    path: 'project/indexado/edit/:id',
    component: AddComponent
  },
  {
    path: 'project/hotspots',
    loadChildren: () => import('./hotspots/hotspots.module').then(m => m.HotspotsModule)
  },
];

@NgModule({
  declarations: [
    AddComponent,
    ListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTableModule,
    MatDialogModule,
    MatRippleModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatRadioModule,
    MatChipsModule,
    MatMenuModule,
    FuseProgressBarModule,
    
  ]
})
export class ProjectModule { }
