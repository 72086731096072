import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalDialogDataGallery } from '../../../hotspots.model';
import { MultimediaDialogComponent } from '../../../../../../multimedia/dialog/multimedia-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-modal-edit-gallery',
  templateUrl: './modal-edit-gallery.component.html',
  styleUrls: ['./modal-edit-gallery.component.scss']
})
export class ModalEditGALLERYComponent implements OnInit {

  public images: string[] = [];
  public imageReference: {key: string, value: string};

  constructor(
    public dialogRef: MatDialogRef<ModalEditGALLERYComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalDialogDataGallery,
    public multimedia: MatDialog,
  ) {
    this.imageReference = {key: 'imageReference', value: ''};
    this.images = this.data.images;
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close({action: 'cancel'});
  }

  onClick() {
    this.dialogRef.close({action: 'save', data: this.data.images});
  }

  addImage() {
    const dialogRef = this.multimedia.open(MultimediaDialogComponent, {
      data: {
        element: this.imageReference,
        multi: false,
        fileUploadUrl: environment.apiFilesUrl,
        type: "images"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.storageId) {
          this.data.images = [
            ...this.data.images || [],
            'https://media.alquimio.cloud/images/@thumbs_256_' + result.storageId + '.webp'
          ];
        }
    });
  }

  removeImage(image: string) {
    this.data.images = this.data.images.filter((img: string) => image !== img);
  }

  // handleAddImageFromDevice(event): void {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {      
  //     const obj = this.getImages();
  //     if (obj) {
  //       obj.push(reader.result.toString());
  //     } else {
  //       this.currentHotspot.additionalAttr.push({
  //         key: 'IMAGES',
  //         value: [reader.result.toString()]
  //       });
  //     }
  //   };
  // }

}
