import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { indexOf } from 'lodash';

interface decodedURL {
  hostname: string,
  pathname: string,
  param: string,
  protocol: string
}

interface iframeParams {
  show: boolean,
  class: string,
  width: string,
  src: SafeUrl,
  frameborder: string,
  allowfullscreen: boolean,
  allow: string
}
@Component({
  selector: 'app-content-video',
  templateUrl: './content-video.component.html',
  styleUrls: ['./content-video.component.scss']
})
export class ContentVideoComponent implements OnInit {

  @Input() url: string;

  iframeParams: iframeParams = {
    show: false,
    class: '',
    width: '100%',
    src: '',
    frameborder: '0',
    allowfullscreen: false,
    allow: ''
  }
  
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    const urlData: decodedURL = this.decodeURL(this.url)
    const vimeoControls = document.getElementsByClassName('vp-controls')[0] as HTMLElement
    
    if (vimeoControls) {
      vimeoControls.style.display = 'none'
    }

    if (urlData.hostname.includes('youtube')) {
      this.setYoutubeIframeParams(urlData)
    }
    else if (urlData.hostname.includes('vimeo')) {
      this.setVimeoIframeParams(urlData)
    }
  }

  setYoutubeIframeParams(urlData: decodedURL): void {
    const hostname = urlData.hostname.replace('www.', '')
    const baseURL = urlData.protocol ? `${urlData.protocol}//www.${hostname}/embed` : `https://www.${hostname}/embed`
    const urlString = `${baseURL}/${urlData.param}?autohide=1&controls=0&showinfo=0&modestbranding=1&autoplay=1&loop=1&iv_load_policy=3&rel=0&playlist=${urlData.param}`
    const urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlString)
    
    this.iframeParams = {
      ...this.iframeParams,
      show: true,
      class: 'youtube_iframe',
      src: urlSafe
    }
  }

  setVimeoIframeParams(urlData: decodedURL): void {console.debug('URL PROTOCOL', urlData.protocol, typeof urlData.protocol)
    const hostname = urlData.hostname.replace('www.', '')
    const baseURL = urlData.protocol ? `${urlData.protocol}//player.${hostname}/video` : `https://player.${hostname}/video`
    const urlString = `${baseURL}${urlData.pathname}?background=1&muted=false`
    const urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlString)
    
    this.iframeParams = {
      ...this.iframeParams,
      show: true,
      class: 'vimeo_iframe',
      src: urlSafe
    }
  }

  decodeURL = (url:string): decodedURL => {
    let urlData: decodedURL = {
      hostname: '',
      protocol: '',
      pathname: '',
      param: ''
    }

    if (url) {
      const protocolarizedUrl = url.indexOf('http') == 0 ? url : 'https://' + url
      const auxUrl = new URL(protocolarizedUrl)
      const hostname = auxUrl.hostname
      const protocol = 'https:'
      const pathname = auxUrl.pathname
      
      if (hostname && hostname.includes('youtube')) {
        const param = auxUrl.searchParams.get('v')

        urlData.param = param
      }

      urlData = {
        ...urlData,
        hostname,
        protocol,
        pathname
      }
    }

    return urlData
  }
}
