import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IHotSpot, IHotSpotAdditionalAttr } from '../../../hotspots.model';
import { ModalService } from 'app/services/modal.service';

@Component({
  selector: 'app-section-galery',
  templateUrl: './section-galery.component.html',
  styleUrls: ['./section-galery.component.scss']
})
export class SectionGaleryComponent implements OnInit {

  @Input() sku: string;
  @Input() currentHotspot: IHotSpot;
  @Output() handleAddImageFromDevice = new EventEmitter<any>();
  @Output() handleDeleteImage = new EventEmitter<number>();
  @Output() handleSort = new EventEmitter<{row: number, direction: number}>();
  @Input() images: string[];

  private isOpen = true

  constructor(
    private modalDialogService: ModalService
  ) {}

  ngOnInit(): void {
  }

  addImageToGallery = () => {
    this.modalDialogService.selectModalType('EDIT_GALLERY_MODAL', {
      title: 'Agregar Imagen',
      sku: this.sku,
      edit: false
    },  this.cbFunc)
  }

  cbFunc = async (data:any) => {
    console.log('data',data)
    const obj = this.getImages();
    const file = data.icon
    const reader = new FileReader();

    if(file instanceof File) {
      reader.readAsDataURL(file);
      reader.onload = () => {      
        if (data.index || data.index === 0) {
          obj[data.index].icono = reader.result.toString(),
          obj[data.index].title = data.title
          obj[data.index].text = data.description
          return
        }
        if (obj) {
          obj.push({
            text: data.description,
            title: data.title,
            icono: reader.result.toString(),
          })
        } else {
          this.currentHotspot.additionalAttr.push({
            key: 'IMAGES',
            value: [{
              text: data.description,
              title: data.title,
              icono: reader.result.toString(),
            }]
          });
        }
      };
    } else {
      console.log('data',data)
      if (data.index || data.index === 0) {
        obj[data.index].icono = data.icon
        obj[data.index].title = data.title
        obj[data.index].text = data.description
        return
      }
      if (obj) {
        console.log('obj',obj)

        obj.push({
          text: data.description,
          title: data.title,
          icono: data.icon
        })
      } else {
        console.log('IMAGES',data)

        this.currentHotspot.additionalAttr.push({
          key: 'IMAGES',
          value: [{
            text: data.description,
            title: data.title,
            icono: data.icon
          }]
        });
      }
    }
  }
  
  getImages(): {title: string, icono: string, text: string}[] {
    const obj = this.currentHotspot ? this.currentHotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'IMAGES') : null;
    return obj ? obj.value : null;
  }

  addImageFromDevice(event: any): void {
    this.handleAddImageFromDevice.emit(event);
  }

  deleteImage(index: number): void {
    this.handleDeleteImage.emit(index);
  }

  sort(row: number, direction: number): void {
    this.handleSort.emit({
      row,
      direction
    });
  }

  openAccordeonElement(open: boolean): void {
    this.isOpen = open
  }
}
